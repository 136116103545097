import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IAppItem} from '../../../Models/ApiModels';
import {IOrdersStatisticItem} from '../../../Store/APIs/Campaign.api';

interface IOrdersStatisticState {
  dateStart: string,
  dateStop: string,
  selectedApps: IAppItem[],
  appInfo: IOrdersStatisticItem | null
}

const initialState: IOrdersStatisticState = {
  dateStart: '',
  dateStop: '',
  selectedApps: [],
  appInfo: null
};

export const myOrdersStatisticSlice = createSlice({
  name: 'ordersStatisticSlice',
  initialState,
  reducers: {
    setStatisticDateStart: (state, action: PayloadAction<string>) => {
      state.dateStart = action.payload;
    },
    setStatisticDateStop: (state, action: PayloadAction<string>) => {
      state.dateStop = action.payload;
    },
    addNewFilterApp: (state, action: PayloadAction<IAppItem>) => {
      state.selectedApps.push(action.payload);
    },
    deleteFilterApp: (state, action: PayloadAction<number>) => {
      state.selectedApps.splice(state.selectedApps.findIndex(app => app.id === action.payload), 1);
    },
    setAppInfo: (state, action: PayloadAction<IOrdersStatisticItem | null>) => {
      state.appInfo = action.payload;
    },
    clearStatisticState: (state) => {
      state.selectedApps = [];
      state.appInfo = null;
      state.dateStop = '';
      state.dateStart = '';
    }
  }
});

export const myOrdersStatisticActions = myOrdersStatisticSlice.actions;

export const myOrdersStatisticReducer = myOrdersStatisticSlice.reducer;