import styled from 'styled-components';
import {IThemeProvider} from '../../../../Theme/defaultTheme';

export const HelpButtonContainer = styled.div`
  position: relative;
`;

export const HelpPopup = styled.div<IThemeProvider>`
  position: absolute;
  top: -16px;

  transform: translateY(-100%);

  display: none;

  background-color: ${props => props.theme.colors.primary_light};
  padding: ${props => props.theme.format.main_md_padding};
  border-radius: ${props => props.theme.format.main_md_border_radius};

  width: max-content;
  max-width: 250px;
  border: 1px solid ${props => props.theme.colors.grey_semi};
  z-index: 15;

  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;

    background-color: ${props => props.theme.colors.primary_light};
    border-bottom: 1px solid ${props => props.theme.colors.grey_semi};
    border-right: 1px solid ${props => props.theme.colors.grey_semi};
    border-radius: 2px;

    position: absolute;
    bottom: 0;
    transform: translateY(50%) rotate(45deg);
  }

  &[data-side='right'] {
    left: -30px;

    &::before {
      left: 31px;
    }
  }

  &[data-side='left'] {
    right: -30px;

    &::before {
      right: 31px;
    }
  }
`;

export const StyledHelpButton = styled.div<IThemeProvider>`
  color: ${props => props.theme.colors.grey};
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    color: ${props => props.theme.colors.black_80};
  }

  &:hover + ${HelpPopup} {
    display: block;
  }
`;

export const HelpPopupText = styled.p`
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};
`;