import React, {FC} from 'react';
import {AppsListStatisticWrapper, StatisticExportBlock, StatisticTableBlock, StatisticTableEmptyText} from '../styled';
import {
  DetailHeader,
  DetailHeaderStore,
  DetailHeaderTitle,
  DetailHeading,
  DetailReturnButton
} from './styled';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {useGetStatisticGroupQuery} from '../../../../../../../Store/APIs/Campaign.api';
import BlockLoader from '../../../../../../../Components/Loaders/BlockLoader';
import {useActions} from '../../../../../../../Hooks/redux/useActions';
import BasicStoreIcon from '../../../../../../../UI/icons/BasicStoreIcon';
import DetailTable from '../../tables/DetailTable/DetailTable';
import BasicButton from '../../../../../../../UI/BasicButton';
import {parseGroupStatistic} from '../../../utils';
import {utils, writeFile} from 'xlsx';

interface DetailAppStatisticProps {
  detailId: number;
}

// const GROUP_BY_BUTTONS: groupButtonType[] = [
//   {
//     title: 'By day',
//     value: 'day'
//   },
//   {
//     title: 'By week',
//     value: 'week'
//   },
//   {
//     title: 'By month',
//     value: 'month'
//   }
// ];

const DetailAppStatistic: FC<DetailAppStatisticProps> = ({detailId}) => {
  const {dateStart, dateStop} = useAppSelector(state => state.myOrdersStatisticSlice);

  const {appInfo} = useAppSelector(state => state.myOrdersStatisticSlice);
  const {setAppInfo} = useActions();

  const {data = [], isLoading, isSuccess} = useGetStatisticGroupQuery({
    app: detailId,
    dateStart,
    dateStop,
    groupBy: 'day'
  });

  // const handleChangeGroup = (group: GroupByType) => {
  //   setGroupBy(group);
  // };

  const handleExport = () => {
    if (!data) return;

    const newTableRows = parseGroupStatistic(data, {countryTotal: false});

    const headerRow = ['Country', 'Key', ...newTableRows.dateList];
    const worksheetData = [headerRow];

    newTableRows.table.forEach(row => {
      const {country, keyword, ...dates} = row;

      const sheetRow: string[] = [country, keyword];

      newTableRows.dateList.forEach(date => {
        sheetRow.push(String(dates[date]));
      });

      worksheetData.push(sheetRow);
    });

    const footerRow = ['Total', '', ...newTableRows.dateList.map(date => String(newTableRows.footer[date]))];

    worksheetData.push(footerRow);

    const ws = utils.aoa_to_sheet(worksheetData);
    const wb = utils.book_new();

    utils.book_append_sheet(wb, ws, `Data`);

    writeFile(wb, `${appInfo?.application_name}_${dateStart}-${dateStop}.xlsx`);
  };

  return (
    <AppsListStatisticWrapper>
      {appInfo ?
        <>
          <DetailHeading>
            <DetailReturnButton onClick={() => setAppInfo(null)}>
          <span>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M11.2808 4.23678C11.4937 4.43204 11.4937 4.74862 11.2808 4.94388L7.69043 8.23678C7.47753 8.43204 7.47753 8.74862 7.69043 8.94388L11.2808 12.2368C11.4937 12.432 11.4937 12.7486 11.2808 12.9439C11.0679 13.1391 10.7227 13.1391 10.5098 12.9439L6.91945 9.65099C6.28076 9.06521 6.28075 8.11546 6.91945 7.52967L10.5098 4.23678C10.7227 4.04152 11.0679 4.04152 11.2808 4.23678Z"
                    fill="#A7A7A7"/>
            </svg>
          </span>
            </DetailReturnButton>
            <DetailHeader>
              <DetailHeaderStore>
                <BasicStoreIcon store={appInfo.application_store_id.includes('.') ? 'google' : 'apple'}/>
              </DetailHeaderStore>
              <DetailHeaderTitle>{appInfo.application_name} details for {dateStart} - {dateStop}</DetailHeaderTitle>
            </DetailHeader>
          </DetailHeading>
          {/*<GroupBySelector>*/}
          {/*  {GROUP_BY_BUTTONS.map(group =>*/}
          {/*    <GroupByButton*/}
          {/*      onClick={() => handleChangeGroup(group.value)}*/}
          {/*      disabled={groupBy === group.value}*/}
          {/*      key={group.value}>{group.title}</GroupByButton>*/}
          {/*  )}*/}
          {/*</GroupBySelector>*/}

          <StatisticTableBlock>
            {isLoading ? <BlockLoader size={'lg'}/> : null}
            {(isSuccess && !data.length) ?
              <StatisticTableEmptyText>No data</StatisticTableEmptyText>
              : <DetailTable list={data}/>
            }
          </StatisticTableBlock>
          <StatisticExportBlock>
            <BasicButton onClick={handleExport} size={'md'} text={'Export xlsx'} variant={'blue'}/>
          </StatisticExportBlock>
        </> : null
      }
    </AppsListStatisticWrapper>
  );
};

export default DetailAppStatistic;