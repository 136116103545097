import React, {useCallback, useEffect, useState} from 'react';
import {AttributeRow, AttributeRowHeading, AttributeRowLabel, AttributeRowTitle} from '../../styled';
import HelpButton from '../../../../../Components/HelpButton';
import BasicCheckboxButton from '../../../../../../../UI/BasicCheckboxButton';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {IAppItem} from '../../../../../../../Models/ApiModels';
import {useGetAppsQuery} from '../../../../../../../Store/APIs/Apps.api';
import {CreateOrderScenarioType} from '../../../../../Slicies/newCampaign.slice';

interface ConsoleVisibilityRowProps {
  selectedCheckboxesValues: string[];
  handleSetSelectedCheckboxesValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setCheckboxesValue: (checkboxes: string[]) => void;
  activeScenario: CreateOrderScenarioType;
}

const ConsoleVisibilityRow: React.FC<ConsoleVisibilityRowProps> = ({
                                                                     selectedCheckboxesValues,
                                                                     handleSetSelectedCheckboxesValue,
                                                                     setCheckboxesValue,
                                                                     activeScenario
                                                                   }) => {
  const {app} = useAppSelector(state => state.newOrderSlice);

  const [selectedAppInfo, setSelectedAppInfo] = useState<IAppItem | null>(null);

  const {data: appsList = []} = useGetAppsQuery({search: '', limit: 999, offset: 0});

  const getCurrentAppInfo = useCallback((appId: number, appsList: IAppItem[]) => {
    // Получение информации о выбранном приложении
    if (appsList.length) {
      const currentAppInfo = appsList.find(application => application.id === appId);

      if (currentAppInfo) {
        setSelectedAppInfo(currentAppInfo);
      } else {
        if (selectedCheckboxesValues.some((attribute) => attribute === 'consoleVisible')) {
          handleDeleteConsoleVisibleAttribute();
        }

        setSelectedAppInfo(null);
      }
    }
  }, [setSelectedAppInfo, selectedCheckboxesValues]);

  useEffect(() => {
    // Эффект с тригером на выбор нового приложения и получение списка приложений
    getCurrentAppInfo(app, appsList);
  }, [app, appsList]);

  const handleDeleteConsoleVisibleAttribute = () => {
    const newArr: string[] = [...selectedCheckboxesValues];

    newArr.splice(newArr.indexOf('consoleVisible'), 1);

    setCheckboxesValue(newArr);
  };

  useEffect(() => {
    // Эффект проверяющий смену стора приложения для очистки консоль висибилити
    if (selectedAppInfo && selectedAppInfo.url.includes('apple') && selectedCheckboxesValues.some((attribute) => attribute === 'consoleVisible')) {
      handleDeleteConsoleVisibleAttribute();
    }
  }, [selectedAppInfo]);

  return (
    <AttributeRow>
      <AttributeRowHeading>
        <AttributeRowLabel>
          <AttributeRowTitle>Console visibility</AttributeRowTitle>
          <HelpButton
            popupText={'Select this option if you want the installation information to be displayed in the Google Play console'}/>
        </AttributeRowLabel>
        <BasicCheckboxButton
          name={'attributes'}
          value={'consoleVisible'}
          checked={!!selectedCheckboxesValues.find(attribute => attribute === 'consoleVisible')}
          onChange={handleSetSelectedCheckboxesValue}
          isDisabled={activeScenario === 'extend' || !selectedAppInfo || selectedAppInfo.url.includes('apple')}
        />
      </AttributeRowHeading>
    </AttributeRow>
  );
};

export default ConsoleVisibilityRow;