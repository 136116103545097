import styled from 'styled-components';
import {StyledButton} from '../../../../UI/BasicButton/styled';

export const PeriodButtonBlock = styled.div`
  display: flex;
  width: min-content;
  align-items: center;
  gap: 18px;
`;

export const PeriodStatisticButton = styled(StyledButton)`
  height: 41px;

  & span {
    width: 20px;
    height: 15px;

    & svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;