import React, {FC} from 'react';
import {
  StatisticAppRow,
  StatisticSumTd,
  StatisticTd,
  StatisticTFoot,
  StatisticTh,
  StatisticTHead,
  TableIcon
} from '../styled';
import {IOrdersStatisticItem} from '../../../../../../../Store/APIs/Campaign.api';
import {BasicContentSpan} from '../../../../../../../Theme/basicElements';
import BasicStoreIcon from '../../../../../../../UI/icons/BasicStoreIcon';
import BasicButton from '../../../../../../../UI/BasicButton';
import {useActions} from '../../../../../../../Hooks/redux/useActions';
import {getTotalValue} from '../../../utils';

interface StatisticTableProps {
  statisticList: IOrdersStatisticItem[];
}

const StatisticTable: FC<StatisticTableProps> = ({statisticList}) => {
  const {setAppInfo} = useActions();

  function getTableRow(data: IOrdersStatisticItem): {
    title: string,
    key: keyof IOrdersStatisticItem | '',
    tableNode: React.ReactNode
  }[] {
    return [
      {
        title: 'Application',
        key: 'application_name',
        tableNode: (
          <StatisticAppRow>
            <TableIcon>
              <img src={data.application_icon} alt={''}/>
            </TableIcon>
            <BasicContentSpan>{data.application_name}</BasicContentSpan>
          </StatisticAppRow>),
      },
      {
        title: 'Store',
        key: 'application_store_id',
        tableNode: (
          <TableIcon>
            <BasicStoreIcon store={data.application_store_id.includes('.') ? 'google' : 'apple'}/>
          </TableIcon>
        )
      },
      {
        title: 'Countries',
        key: 'countries',
        tableNode: (
          <BasicContentSpan>{data.countries}</BasicContentSpan>
        )
      },
      {
        title: 'Keywords',
        key: 'keywords',
        tableNode: (
          <BasicContentSpan>{data.keywords}</BasicContentSpan>
        )
      },
      {
        title: 'Active Campaigns',
        key: 'activeCampaigns',
        tableNode: (
          <BasicContentSpan>{data.activeCampaigns}</BasicContentSpan>
        )
      },
      {
        title: 'Completed Campaigns',
        key: 'completedCampaigns',
        tableNode: (
          <BasicContentSpan>{data.completedCampaigns}</BasicContentSpan>
        )
      },
      {
        title: 'Cancelled Campaigns',
        key: 'cancelledCampaigns',
        tableNode: (
          <BasicContentSpan>{data.cancelledCampaigns}</BasicContentSpan>
        )
      },
      {
        title: 'Installs',
        key: 'installsCount',
        tableNode: (
          <BasicContentSpan>{data.installsCount}</BasicContentSpan>
        )
      },
      {
        title: 'Rates',
        key: 'ratesCount',
        tableNode: (
          <BasicContentSpan>{data.ratesCount}</BasicContentSpan>
        )
      },
      {
        title: 'Cost',
        key: 'cost',
        tableNode: (
          <BasicContentSpan>{data.cost.toFixed(2)}</BasicContentSpan>
        )
      },
      {
        title: '',
        key: '',
        tableNode: (
          <BasicButton
            onClick={() => handleOpenDetails(data)}
            size={'sm'}
            text={'Details'}
            variant={'blue'}/>
        )
      },
    ];
  }

  const handleOpenDetails = (app: IOrdersStatisticItem) => {
    setAppInfo(app);
  };

  return (
    <table>
      <StatisticTHead>
        <tr>
          {getTableRow(statisticList[0]).map(cell =>
            <StatisticTh key={cell.title}>{cell.title}</StatisticTh>
          )}
        </tr>
      </StatisticTHead>
      <tbody>
      {statisticList.map(item =>
        <tr key={item.application_store_id}>
          {getTableRow(item).map((row) =>
            <StatisticTd key={row.key}>
              {row.tableNode}
            </StatisticTd>
          )}
        </tr>
      )
      }
      </tbody>
      <StatisticTFoot>
        <tr>
          <StatisticSumTd>Total:</StatisticSumTd>
          <StatisticSumTd></StatisticSumTd>
          <StatisticSumTd>{getTotalValue(statisticList, 'countries')}</StatisticSumTd>
          <StatisticSumTd>{getTotalValue(statisticList, 'keywords')}</StatisticSumTd>
          <StatisticSumTd>{getTotalValue(statisticList, 'activeCampaigns')}</StatisticSumTd>
          <StatisticSumTd>{getTotalValue(statisticList, 'completedCampaigns')}</StatisticSumTd>
          <StatisticSumTd>{getTotalValue(statisticList, 'cancelledCampaigns')}</StatisticSumTd>
          <StatisticSumTd>{getTotalValue(statisticList, 'installsCount')}</StatisticSumTd>
          <StatisticSumTd>{getTotalValue(statisticList, 'ratesCount')}</StatisticSumTd>
          <StatisticSumTd>{getTotalValue(statisticList, 'cost').toFixed(2)}</StatisticSumTd>
          <StatisticSumTd></StatisticSumTd>
        </tr>
      </StatisticTFoot>
    </table>
  );
};

export default StatisticTable;