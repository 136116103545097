import React, {forwardRef, useCallback, useEffect, useState} from 'react';
import {DelayStartBodyWrapper, TimeSelectorBlock, TimeSelectorInput, TimeSelectorWarning} from './styled';
import DatePicker from 'react-datepicker';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {getDateWithoutGTMOffset} from '../../../../../../../Utils/DateUtils';
import {AttributeBody, AttributeRow, AttributeRowHeading, AttributeRowLabel, AttributeRowTitle} from '../../styled';
import HelpButton from '../../../../../Components/HelpButton';
import BasicCheckboxButton from '../../../../../../../UI/BasicCheckboxButton';
import {getDataFromStorage} from '../../../../../../../Utils/localStorageHelpers';
import DelayAttentionModal from '../DelayAttentionModal';
import {Modal} from '@mui/material';
import {CreateOrderScenarioType} from '../../../../../Slicies/newCampaign.slice';

interface ICustomDatePickerSelectorProps {
  value?: string;
  onClick?: () => void;
  placeholder?: string;
  disabled: boolean;
}

const CustomDatePickerSelector = forwardRef<HTMLInputElement, ICustomDatePickerSelectorProps>(({
                                                                                                 value,
                                                                                                 onClick,
                                                                                                 placeholder,
                                                                                                 disabled
                                                                                               }, ref) => (
  <TimeSelectorInput
    onClick={onClick}
    value={value}
    placeholder={placeholder}
    ref={ref}
    disabled={disabled}
    readOnly
    data-in-error={!(!disabled && value)}
  />
));

interface DelayStartRowProps {
  handleSetDelayStartAttribute: (value: boolean) => void;
  handleSetDelayStartValue: (timeString: string | null) => void,
  activeScenario: CreateOrderScenarioType,
}

const DelayStartRow: React.FC<DelayStartRowProps> = ({
                                                       handleSetDelayStartAttribute,
                                                       handleSetDelayStartValue,
                                                       activeScenario
                                                     }) => {
  const {
    dateStart,
    delayedStartValue,
    delayedStartEnable,
    countriesList
  } = useAppSelector(state => state.newOrderSlice);

  const [attentionIsViewed, setAttentionIsViewed] = useState(false);
  const [openDelayAttentionModal, setOpenDelayAttentionModal] = useState(false);

  const checkingCountriesCount = useCallback((checked: boolean) => {
    //функция которая проверяет кол-во стран и статус отключения показа модалки предупреждения
    const disableModal = getDataFromStorage('disable_delay_modal');

    if (countriesList.length > 1 && !disableModal && checked && !attentionIsViewed) {
      setOpenDelayAttentionModal(true);
    }
  }, [setOpenDelayAttentionModal, attentionIsViewed, getDataFromStorage, countriesList]);

  useEffect(() => {
    checkingCountriesCount(delayedStartEnable);
  }, [countriesList, checkingCountriesCount]);

  const handleCloseModal = () => {
    setOpenDelayAttentionModal(false);

    setAttentionIsViewed(true);
  };

  const handleToggleDelay = (event: React.ChangeEvent<HTMLInputElement>) => {
    checkingCountriesCount(event.target.checked);

    handleSetDelayStartAttribute(event.target.checked);

    // if (!event.target.checked) {
    //   handleSetDelayStartValue(null);
    // }
  };

  const getMaxTimeValue = () => {
    return new Date(new Date().setHours(23, 59, 0, 0));
  };

  const getSelectedTime = () => {
    if (delayedStartValue && dateStart) {
      const [hours, minutes] = delayedStartValue.split(' ')[0].split(':');

      const currentDate = new Date(dateStart);
      currentDate.setHours(Number(hours), Number(minutes));
      return currentDate;
    } else {
      return null;
    }
  };

  const padZero = (value: number): string => {
    return value.toString().padStart(2, '0');
  };

  const handleSetTimeString = (time: Date | null) => {
    if (time) {
      const hours = time.getHours();
      const minutes = time.getMinutes();

      handleSetDelayStartValue(`${padZero(hours)}:${padZero(minutes)} +00:00`);
    } else {
      handleSetDelayStartValue(null);
    }
  };

  const getTimeMinValue = (dateStart: string | null) => {
    const currentDate = getDateWithoutGTMOffset(new Date());

    if (dateStart && getDateWithoutGTMOffset(new Date(dateStart)).getTime() <= currentDate.getTime()) {
      return getDateWithoutGTMOffset(new Date());
    } else {
      return new Date(currentDate.setHours(0, 0, 0, 0));
    }
  };

  const isDisabledDelay = () => {
    return (!delayedStartEnable || !dateStart || activeScenario === 'extend');
  };

  return (
    <AttributeRow>
      <AttributeRowHeading>
        <AttributeRowLabel>
          <AttributeRowTitle>Delayed start</AttributeRowTitle>
          <HelpButton popupText={'Specify the exact time when you need to start the campaign.'}/>
        </AttributeRowLabel>
        <BasicCheckboxButton
          name={'attributes'}
          value={'consoleVisibility'}
          checked={delayedStartEnable}
          onChange={handleToggleDelay}
          isDisabled={activeScenario === 'extend'}
        />
      </AttributeRowHeading>
      <AttributeRowTitle data-is-disabled={!delayedStartEnable}>Set time</AttributeRowTitle>
      <AttributeBody>
        <DelayStartBodyWrapper>
          <TimeSelectorBlock>
            <DatePicker
              selected={getSelectedTime()}
              minTime={getTimeMinValue(dateStart)}
              maxTime={getMaxTimeValue()}
              onChange={handleSetTimeString}
              customInput={<CustomDatePickerSelector disabled={!dateStart || isDisabledDelay()}/>}
              placeholderText={'Select offset'}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              disabled={(isDisabledDelay() || activeScenario === 'extend')}
            />
            {(delayedStartEnable && !dateStart) &&
              <TimeSelectorWarning>Specify the start date of the campaign</TimeSelectorWarning>}
          </TimeSelectorBlock>
        </DelayStartBodyWrapper>
      </AttributeBody>
      <AttributeRowTitle data-is-disabled={!delayedStartEnable}>UTC +0</AttributeRowTitle>
      <Modal open={openDelayAttentionModal} onClose={handleCloseModal}>
        <DelayAttentionModal onClose={handleCloseModal}/>
      </Modal>
    </AttributeRow>
  );
};

export default DelayStartRow;