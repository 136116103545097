import {useDispatch} from 'react-redux';
import {bindActionCreators} from '@reduxjs/toolkit';
import {errorsActions} from '../../Store/Slices/errors.slice';
import {authActions} from '../../Store/Slices/auth.slice';
import {asideActions} from '../../Store/Slices/aside.slice';
import {myOrdersFiltersActions} from '../../Pages/MyOrdersPage/Slicies/myOrdersFilters.slice';
import {myOrdersColumnsActions} from '../../Pages/MyOrdersPage/Slicies/myOrdersColumns.slice';
import {newOrderActions} from '../../Pages/CreateCampaignPage/Slicies/newCampaign.slice';
import {myOrdersStatisticActions} from '../../Pages/MyOrdersPage/Slicies/myOrdersStatistic.slice';

const actions = {
  ...errorsActions,
  ...authActions,
  ...asideActions,
  ...myOrdersFiltersActions,
  ...myOrdersColumnsActions,
  ...myOrdersStatisticActions,
  ...newOrderActions
};
export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};