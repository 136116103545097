import React, {FC, useEffect, useMemo, useState} from 'react';
import {IGetStatisticGroupResponseItem} from '../../../../../../../Store/APIs/Campaign.api';
import {StatisticSumTd, StatisticTd, StatisticTFoot, StatisticTh, StatisticTHead, StatisticTr} from '../styled';
import {formatWeekString} from '../../../../../../../Utils/DateUtils';
import {ITableRows} from '../../../models/detailsGroupTypes';
import {parseGroupStatistic} from '../../../utils';

interface DetailTableProps {
  list: IGetStatisticGroupResponseItem[];
}

const DetailTable: FC<DetailTableProps> = ({list}) => {
  const [tableRows, setTableRows] = useState<ITableRows | null>(null);

  useEffect(() => {
    if (!list.length) return;

    const newTableRows = parseGroupStatistic(list, {countryTotal: true});

    setTableRows(newTableRows);
  }, [list]);

  const memoizedTableRows = useMemo(() => {
    if (!tableRows) return (<></>);

    return (
      <>
        {tableRows.table.map((row, rowIndex) => {
            const {keyword, country, ...values} = row;

            const valuesArr = values;

            return (
              <StatisticTr key={keyword + country + String(rowIndex)} data-row={keyword === 'Total' ? 'total' : 'row'}>
                <StatisticTd data-align="left">{country}</StatisticTd>
                <StatisticTd data-align="left">{keyword}</StatisticTd>
                {
                  tableRows.dateList.map((date, dateIndex) =>
                    <StatisticTd data-align="right"
                                 key={String(dateIndex) + date + String(rowIndex)}>{valuesArr[date]}</StatisticTd>
                  )
                }
              </StatisticTr>
            );
          }
        )}
      </>);
  }, [tableRows]);

  const memoizedFooterRow = useMemo(() => {
    if (!tableRows) return (<></>);

    const {keyword, country, ...values} = tableRows.footer;

    const valuesArr = values;

    return (
      <tr>
        <StatisticSumTd data-align="left">{country}</StatisticSumTd>
        <StatisticSumTd data-align="left">{keyword}</StatisticSumTd>
        {
          tableRows.dateList.map((date, dateIndex) =>
            <StatisticSumTd data-align="right"
                            key={String(dateIndex) + date + String('footer')}>{valuesArr[date]}</StatisticSumTd>
          )
        }
      </tr>
    );
  }, [tableRows]);

  return (
    tableRows ?
      <table>
        <StatisticTHead>
          <tr>
            <StatisticTh data-align="left">Country</StatisticTh>
            <StatisticTh data-align="left">Key</StatisticTh>
            {tableRows.dateList.map((date, index) =>
              <StatisticTh data-align="right" key={date + String(index + 1000)}>
                {formatWeekString(date)}
              </StatisticTh>
            )}
          </tr>
        </StatisticTHead>
        <tbody>
        {memoizedTableRows}
        </tbody>
        <StatisticTFoot>
          {memoizedFooterRow}
        </StatisticTFoot>
      </table> : null
  );
};

export default DetailTable;