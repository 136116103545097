import {ICountryModel, INewCampaignObject} from '../Models/CountryModel';
import {getDatesBetween, getDateWithoutGTMOffset} from '../../../Utils/DateUtils';
import {COUNTRIES_LIST} from '../../../Constants/CountriesList';
import {IAppItem, IClientData} from '../../../Models/ApiModels';
import {ATTRIBUTES_VALUES} from '../Presentor/Modules/CreateCampaignAttributesView/Constants/AttributesValues';

export const getDatesListFromCountry = (country: ICountryModel): string[] => Object.keys(country.keywords);
//возвращает массив дат из объекта страны

export const getKeysFromCountry = (country: ICountryModel): string[] => Object.keys(country.keywords[Object.keys(country.keywords)[0]]);
//возвращает массив ключей из первого дня объекта keywords у страны

export const checkLengthOfKeywords = (country: ICountryModel): boolean => !!Object.keys(country.keywords).length;
//проверяет существование массива ключей

export const getFirstCountryDate = (country: ICountryModel) => getDatesListFromCountry(country)[0];
//функция возвращает первую дату страны

export const getFirstCountryKeywordValue = (country: ICountryModel, key: string) => Number(country.keywords[getFirstCountryDate(country)][key]);

export const getNewDateArray = (firstDay: string, startDate: string, endDate: string) => {
  const dateArray = [];
  const currentDate = getDateWithoutGTMOffset(new Date(firstDay));
  const numberOfDays = getDatesBetween(startDate, endDate).length;

  for (let i = 0; i < numberOfDays; i++) {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    dateArray.push(`${year}-${month}-${day}`);

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
};

export const getTotalKeywordsCountFromCountries = (countriesList: ICountryModel[]) => {
  //получение кол-ва ключей в странах
  let keywordCountTemp = 0;
  if (countriesList.length > 0) {
    for (const country of countriesList) {
      const keywords = Object.values(country.keywords);

      for (const keywordObj of keywords) {
        const values = Object.values(keywordObj);
        keywordCountTemp += values.reduce((acc, value) => acc + Number(value), 0);
      }
    }
    return keywordCountTemp;
  } else {
    return 0;
  }
};

const getLaunchCost = (launchCost: number, attributesValue: number) => {
  const launchAttributes: string[] = [];

  for (let attribute in ATTRIBUTES_VALUES) {
    if (attribute.includes('day') && (attributesValue & ATTRIBUTES_VALUES[attribute]) !== 0) {
      launchAttributes.push(attribute);
    }
  }

  if (!launchAttributes.length) return 0;

  if (launchAttributes.length === 1) {
    return launchAttributes.length * launchCost;
  } else {
    return (launchAttributes.length - 1) * launchCost;
  }
};

export const getTotalCostValue = (app: number, type: string | null, user: IClientData | null, appsList: IAppItem[], countriesList: ICountryModel[], attributesValue: number) => {
  if (app && type && user && appsList) {
    const currentAppObj = appsList.find((application) => application.id === app);

    if (currentAppObj) {
      if (currentAppObj.url.includes('apple')) {
        if (type === 'install' || type === 'install_bp') {
          return getTotalKeywordsCountFromCountries(countriesList) * (user.a_install_cost + getLaunchCost(user.cost_relaunch, attributesValue));
        }
        if (type === 'rate') {
          return getTotalKeywordsCountFromCountries(countriesList) * (user.a_rate_cost + getLaunchCost(user.cost_relaunch, attributesValue));
        }
      } else if (currentAppObj.url.includes('google')) {
        if (type === 'install' || type === 'install_bp') {
          return getTotalKeywordsCountFromCountries(countriesList) * (user.install_cost + getLaunchCost(user.cost_relaunch, attributesValue));
        }
        if (type === 'rate') {
          return getTotalKeywordsCountFromCountries(countriesList) * (user.rate_cost + getLaunchCost(user.cost_relaunch, attributesValue));
        }
      }
    }
  }
  return null;
};

export const checkDelayDateIsExist = (delayedTime: string, dateStart: string) => {
  //Проверка выбранного отложенного времени на то истекло оно или нет.
  //возвращает true если время прошло
  const [hours] = delayedTime.split(' ');

  const nowTime = getDateWithoutGTMOffset(new Date()).getTime();

  const orderTime = (getDateWithoutGTMOffset(new Date(dateStart)).setHours(Number(hours.split(':')[0]), Number(hours.split(':')[1])));

  return nowTime >= orderTime;
};

export const validationFinalOrderForm = (campaignObject: INewCampaignObject) => {
  const errorsList: string[] = [];

  if (!campaignObject.app_id) {
    errorsList.push('error_lack_app');
  }

  if (!campaignObject.type) {
    errorsList.push('error_lack_type');
  }

  if (!campaignObject.date_start || campaignObject.date_start === 'Invalid date' || !campaignObject.date_stop || campaignObject.date_stop === 'Invalid date') {
    errorsList.push('error_lack_date');
  }

  if (campaignObject.delayed_start && campaignObject.date_start && checkDelayDateIsExist(campaignObject.delayed_start, campaignObject.date_start)) {
    errorsList.push('error_delayed_time_exist');
  }

  if (!campaignObject.keywords.length) {
    errorsList.push('error_lack_countries');
  }

  if (!campaignObject.keywords.every(country => country.geo !== '' && !!COUNTRIES_LIST.find((c) => country.geo === c.ISO))) {
    //todo сделать проверку каждой страны и выводить список неверных полей
    errorsList.push('error_unselect_country');
  }

  if (!campaignObject.keywords.every(country => Object.keys(country.keywords).some(date =>
    Object.keys(country.keywords[date]).some(key => {
      return country.keywords[date][key] !== 0;
    })
  ))) {
    errorsList.push('error_no_values_keys');
  }

  return errorsList;
};

export const getFileExtension = (filename: string) => {
  return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2); // Извлекаем расширение файла из его имени
};

export const getTotalCurrentTypeName = (orderType: string) => {
  //функция, которая возвращает правильное отображение типа кампании
  const typeNames: { [key: string]: string } = {
    install: 'installs',
    rate: 'rates',
    'install_bp': 'installs'
  };

  return typeNames[orderType];
};