import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {AppsApi} from './APIs/Apps.api';
import {AuthApi} from './APIs/Auth.api';
import {errorsReducer} from './Slices/errors.slice';
import {authReducer} from './Slices/auth.slice';
import {asideReducer} from './Slices/aside.slice';
import {clientDataReducer} from './Slices/clientData.slice';
import {CampaignApi} from './APIs/Campaign.api';
import {PaymentsApi} from './APIs/Payments.api';
import {ClientDataApi} from './APIs/ClientData.api';
import {myOrdersFiltersReducer} from '../Pages/MyOrdersPage/Slicies/myOrdersFilters.slice';
import {myOrdersColumnsReducer} from '../Pages/MyOrdersPage/Slicies/myOrdersColumns.slice';
import {newOrderReducer} from '../Pages/CreateCampaignPage/Slicies/newCampaign.slice';
import {myOrdersStatisticReducer} from '../Pages/MyOrdersPage/Slicies/myOrdersStatistic.slice';

const rootReducer = combineReducers({
  [AppsApi.reducerPath]: AppsApi.reducer,
  [AuthApi.reducerPath]: AuthApi.reducer,
  [CampaignApi.reducerPath]: CampaignApi.reducer,
  [PaymentsApi.reducerPath]: PaymentsApi.reducer,
  [ClientDataApi.reducerPath]: ClientDataApi.reducer,
  errorsSlice: errorsReducer,
  authSlice: authReducer,
  asideSlice: asideReducer,
  clientDataSlice: clientDataReducer,
  myOrdersFiltersSlice: myOrdersFiltersReducer,
  myOrdersColumnsSlice: myOrdersColumnsReducer,
  newOrderSlice: newOrderReducer,
  myOrdersStatisticSlice: myOrdersStatisticReducer
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(AppsApi.middleware, AuthApi.middleware, CampaignApi.middleware, PaymentsApi.middleware, ClientDataApi.middleware)
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];