import React from 'react';
import {GoogleLogin, CredentialResponse} from '@react-oauth/google';
import {useLazyGoogleSingOnQuery} from '../../../../Store/APIs/Auth.api';
import {useNavigate} from 'react-router-dom';

interface GoogleAuthButtonProps {
  refKey: string | undefined;
}

const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({refKey}) => {
  const [googleSingOn] = useLazyGoogleSingOnQuery();
  const navigate = useNavigate();

  const onGoogleAuth = (response: CredentialResponse) => {
    if (response.credential) {
      googleSingOn({creditals: response.credential, referral: refKey}).unwrap()
        .then(() => {
          navigate('/');
        }).catch((error) => {
        if (error.data.status === 'Registration is closed') {
          navigate('/registration');
        }
      });
    }
  };

  return (
    <>
      <GoogleLogin
        size={'medium'}
        theme={'filled_blue'}
        shape={'circle'}
        onSuccess={onGoogleAuth}
        onError={() => {
          console.log('Login Failed');
        }}
      />
    </>
  );
};

export default GoogleAuthButton;