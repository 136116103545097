import {IGetStatisticGroupResponseItem} from '../../../../../Store/APIs/Campaign.api';
import {ITableRows, TableRow} from '../models/detailsGroupTypes';

export const getTotalValue = function <T, K extends keyof T>(array: T[], key: K) {
  let value = 0;

  array.forEach(item => {
    Number(item[key]) && (value += Number(item[key]));
  });

  return value;
};

export const parseGroupStatistic = (list: IGetStatisticGroupResponseItem[], params: {
  'countryTotal': boolean
}): ITableRows => {
  const dateList = Array.from(new Set(list.map(item => String(item.date)))).sort();

  let tableRows: ITableRows = {
    dateList: dateList,
    table: [],
    footer: {
      country: 'Total',
      keyword: ''
    },
  };

  const groupedData: { [key: string]: TableRow } = {};

  list.forEach(item => {
    const key = `${item.country}-${item.keyword}`;
    if (!groupedData[key]) {
      groupedData[key] = {
        country: item.country,
        keyword: item.keyword,
      };
      // Инициализируем даты значениями 0
      dateList.forEach(date => (groupedData[key][date] = 0));
    }
    groupedData[key][item.date] = item.value;
  });

  const tableData: TableRow[] = Object.values(groupedData);

  tableRows.table.push(...tableData);

  if (params.countryTotal) {
    const countryTotal: { [key: string]: TableRow } = {};

    tableData.forEach(row => {
      const key = row.country;

      if (!countryTotal[key]) {
        countryTotal[key] = {
          country: row.country,
          keyword: 'Total',
        };

        dateList.forEach(date => (countryTotal[key][date] = 0));
      }
      dateList.forEach(date => (countryTotal[key][date] = Number(countryTotal[key][date]) + Number(row[date])));
    });

    const tableCountryTotal = Object.values(countryTotal);

    tableCountryTotal.forEach(countryTotalItem => {
      const indexLastCountryRow = tableRows.table.map(row => row.country).lastIndexOf(countryTotalItem.country);

      tableRows.table.splice(indexLastCountryRow + 1, 0, countryTotalItem);
    });
  }

  dateList.forEach(date => {
    tableRows.footer[date] = tableData.reduce((sum, row) => sum + Number(row[date]), 0);
  });

  return tableRows;
};