import React from 'react';
import {AttributeBody, AttributeRow, AttributeRowHeading, AttributeRowLabel, AttributeRowTitle} from '../../styled';
import HelpButton from '../../../../../Components/HelpButton';
import BasicCheckboxButton from '../../../../../../../UI/BasicCheckboxButton';
import DaysListAttributes from '../DaysListAttributes';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {CreateOrderScenarioType} from '../../../../../Slicies/newCampaign.slice';

interface LaunchAppRowProps {
  selectedCheckboxesValues: string[];
  handleSetSelectedCheckboxesValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setCheckboxesValue: (checkboxes: string[]) => void;
  activeScenario: CreateOrderScenarioType;
}

const LaunchAppRow: React.FC<LaunchAppRowProps> = ({
                                                     handleSetSelectedCheckboxesValue,
                                                     selectedCheckboxesValues,
                                                     setCheckboxesValue,
                                                     activeScenario
                                                   }) => {
  const {user} = useAppSelector(state => state.clientDataSlice);

  const handleToggleDaysRow = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSetSelectedCheckboxesValue(event);
    if (!event.target.checked) {
      let newArr: string[] = [...selectedCheckboxesValues];
      const filteredArr = newArr.filter((param) => !param.includes('day'));
      setCheckboxesValue(filteredArr);
    }
  };

  return (
    <AttributeRow>
      <AttributeRowHeading>
        <AttributeRowLabel>
          <AttributeRowTitle>Launch app</AttributeRowTitle>
          <HelpButton
            popupText={
              user?.cost_relaunch ?
                `Select the option if you want your application to run after installation and in the following days. Each launch of the application adds ${user?.cost_relaunch}$ to the installation cost.` :
                'Select the option if you want your application to run after installation and in the following days.'
            }/>
        </AttributeRowLabel>
        <BasicCheckboxButton
          name={'attributes'}
          value={'day'}
          checked={!!selectedCheckboxesValues.find(attribute => attribute === 'day')}
          onChange={handleToggleDaysRow}
          isDisabled={activeScenario === 'extend'}
        />
      </AttributeRowHeading>
      <AttributeRowTitle
        data-is-disabled={!selectedCheckboxesValues.find(attribute => attribute === 'day') || activeScenario === 'extend'}>Day</AttributeRowTitle>
      <AttributeBody>
        <DaysListAttributes
          handleSetSelectedCheckboxesValue={handleSetSelectedCheckboxesValue}
          isDisabled={!selectedCheckboxesValues.find(attribute => attribute === 'day') || activeScenario === 'extend'}
          selectedCheckboxesValues={selectedCheckboxesValues}
        />
      </AttributeBody>
    </AttributeRow>
  );
};

export default LaunchAppRow;