import React, {useEffect} from 'react';
import {useLazyTelegramSingInQuery} from '../../../../Store/APIs/Auth.api';
import {useNavigate} from 'react-router-dom';

declare global {
  interface Window {
    onTelegramAuth?: (user: any) => void;
  }
}

interface IOnTelegramAuthUser {
  auth_date: number;
  first_name: string;
  hash?: string;
  id: number;
  last_name: string;
  photo_url: string;
  username: string;
}

interface TelegramAuthButtonProps {
  refKey: string | undefined;
}

const TelegramAuthButton: React.FC<TelegramAuthButtonProps> = ({refKey}) => {
  const [telegramAuth, {data, isLoading}] = useLazyTelegramSingInQuery();
  const navigate = useNavigate();

  function onTelegramAuth(user: IOnTelegramAuthUser) {
    if (user) {
      const userData = user;
      const userHash = user.hash;

      if (userData.hasOwnProperty('hash')) {
        delete userData.hash;
      }
      const base64Payload = JSON.stringify(userData);

      if (userHash && base64Payload) {
        telegramAuth({hash: userHash, payload: base64Payload, referral: refKey}).unwrap()
          .then(() => {
            navigate('/');
          }).catch((error) => {
          if (error.data.status === 'Registration is closed') {
            navigate('/registration');
          }
        });
      }
    }
    // Дополнительные действия с данными...
  }

  useEffect(() => {
    const scriptTelegram = document.createElement('script');
    scriptTelegram.src = 'https://telegram.org/js/telegram-widget.js?22';
    scriptTelegram.async = true;

    window.onTelegramAuth = onTelegramAuth; // Привязка функции к глобальной области видимости

    document.body.appendChild(scriptTelegram);

    return () => {
      document.body.removeChild(scriptTelegram);
      delete window.onTelegramAuth; // Удаление связанной функции из глобальной области видимости
    };
  }, []);

  return (
    <>
      <script async src="https://telegram.org/js/telegram-widget.js?22" data-telegram-login="AsoFastOrdersBot"
              data-size="large" data-onauth="onTelegramAuth(user)" data-userpic="false"
              data-request-access="write"></script>
    </>
  );
};

export default TelegramAuthButton;