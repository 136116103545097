import styled from 'styled-components';
import {BasicModalTitle} from '../../../../../../../Theme/modalsElements';
import {IThemeProvider} from '../../../../../../../Theme/defaultTheme';

export const DetailHeading = styled.div`
  display: flex;
  gap: 40px;
`;

export const DetailReturnButton = styled.button`
  width: 43px;
  height: 43px;
  background-color: transparent;
  border: 0;

  & span {
    width: 14px;
    height: 24px;

    & svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export const DetailHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const DetailHeaderStore = styled.div`
  width: 24px;
  height: 24px;

  & img {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }

  & a {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }
`;

export const DetailHeaderTitle = styled(BasicModalTitle)`
  margin-bottom: 0;
`;

export const GroupBySelector = styled.div<IThemeProvider>`
  display: flex;
  padding: 4px;
  gap: 6px;

  background-color: #ECF1FC;
  border-radius: 100px;
  width: min-content;
`;

export const GroupByButton = styled.button<IThemeProvider>`
  background-color: transparent;
  border-radius: 100px;
  border: 0;
  width: max-content;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: ${props => props.theme.font_sizes.content};
  color: #000000;

  &:disabled {
    background-color: #ffffff;
    color: #0038B0;
  }
`;

