import React, {useState} from 'react';
import {PeriodButtonBlock, PeriodStatisticButton} from './styled';
import HelpButton from '../../../CreateCampaignPage/Components/HelpButton';
import {useAppSelector} from '../../../../Hooks/redux/useAppSelector';
import {Modal} from '@mui/material';
import OrdersStatisticModule from '../../Modules/OrdersStatisticModule';

const PeriodStatistic = () => {
  const {dateStart, dateStop} = useAppSelector(state => state.myOrdersFiltersSlice);

  const [openStatistic, setOpenStatistic] = useState(false);

  const handleOpenStatistic = () => {
    setOpenStatistic(true);
  };

  const handleCloseStatistic = () => {
    setOpenStatistic(false);
  };

  return (
    <PeriodButtonBlock>
      <PeriodStatisticButton
        disabled={!dateStart || !dateStop}
        data-size={'md'}
        data-variant={'blue'}
        onClick={handleOpenStatistic}
      >
        <span>
          <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="6.60547" width="5.92581" height="14.3732" rx="1.5" fill="currentColor" fillOpacity="1"/>
              <rect x="8.88867" y="0.979492" width="5.92581" height="19.9995" rx="1.5" fill="currentColor"
                    fillOpacity="1"/>
              <rect x="17.7773" y="10.9241" width="5.92581" height="10.0554" rx="1.5" fill="currentColor"
                    fillOpacity="1"/>
          </svg>
        </span>
      </PeriodStatisticButton>
      <HelpButton popupText={'Specify the period to see campaign statistics'} side={'left'}/>

      {(dateStart && dateStop) ?
        <Modal open={openStatistic} onClose={handleCloseStatistic}>
          <OrdersStatisticModule
            initDateStart={dateStart}
            initDateStop={dateStop}
          />
        </Modal> : null
      }
    </PeriodButtonBlock>
  );
};

export default PeriodStatistic;