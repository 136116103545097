import React from 'react';
import {OrdersFilterBlock} from './styled';
import BasicButton from '../../../../UI/BasicButton';
import DateSelector from '../../../../Components/DateSelector';
import {useAppSelector} from '../../../../Hooks/redux/useAppSelector';
import {useActions} from '../../../../Hooks/redux/useActions';
import {useNavigate} from 'react-router-dom';
import {ApplicationSelector} from '../../../../Components/Selectors';
import PeriodStatistic from '../../components/PeriodStatistic';

const OrdersFiltersModule = () => {
  const navigate = useNavigate();

  const {selectedAppId = 0, dateStart, dateStop} = useAppSelector(state => state.myOrdersFiltersSlice);

  const {setOrdersSelectedApp, setOrdersDateStart, setOrdersDateStop} = useActions();

  const handleNavigateNewCampaign = () => {
    navigate('/create_campaign/new');
  };

  const handleSetSelectedApp = (appId: number) => {
    setOrdersSelectedApp(appId);
  };

  return (
    <OrdersFilterBlock>
      <BasicButton
        onClick={handleNavigateNewCampaign}
        size={'md'}
        text={'Add campaign'}
        variant={'blue'}
      />
      <ApplicationSelector
        size={'md'}
        placeholder={'Select app'}
        selectedApplication={selectedAppId}
        handleSelectApplication={handleSetSelectedApp}
        disabled={false}/>
      <DateSelector
        handleGetDateStart={setOrdersDateStart}
        handleGetDateStop={setOrdersDateStop}
        dateStart={dateStart}
        dateStop={dateStop}
      />
      <PeriodStatistic/>

    </OrdersFilterBlock>
  );
};

export default OrdersFiltersModule;