import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {
  ImportModal, ImportModalAttention,
  ImportModalButtonsBlock, ImportModalInput, ImportModalInputLabel,
  ImportModalItem,
  ImportModalList,
  ImportModalListSpan,
  ImportModalTitle, UploadedFileBlock, UploadedFileError
} from './styled';
import DownloadTemplateButton from './Components/DownloadTemplateButton';
import BasicButton from '../../../../UI/BasicButton';
import {BasicSmallSpan} from '../../../../Theme/basicElements';
import ClearInputButton from '../../../../UI/ClearInputButton';
import {ICountryModel} from '../../Models/CountryModel';
import {getStoreLinkId, throwNewToastError} from '../../../../Utils/MainUtils';

import * as XLSX from 'xlsx';
import {useGetAppsQuery} from '../../../../Store/APIs/Apps.api';
import {getCountryByAssociation, getImportedDateList} from '../../Helpers/ImportHelpers';
import {Modal} from '@mui/material';
import AddNewAppModal from '../../../MyAppsPage/Modules/AppsCardsList/Modals/AddNewAppModal';
import {useActions} from '../../../../Hooks/redux/useActions';
import CustomLoader from '../../../../Components/Loaders/CustomLoader';
import {getFileExtension} from '../../Helpers/CreateOrderHelpers';
import {getDateWithoutGTMOffset} from '../../../../Utils/DateUtils';

interface IImportOrderModuleProps {
  handleCloseImportModal: () => void;
  handleSetOrderApp: (app: number) => void;
  handleSetDateStart: (date: Date | null) => void;
  handleSetDateStop: (date: Date | null) => void;
  handleSetCountriesListInStorage: (countriesList: ICountryModel[]) => void;
  handleSetOrderType: (newType: string | null, prevType: string | null) => void;
}

const ImportOrderModule = forwardRef(({
                                        handleCloseImportModal,
                                        handleSetOrderApp,
                                        handleSetDateStart,
                                        handleSetDateStop,
                                        handleSetCountriesListInStorage,
                                        handleSetOrderType
                                      }: IImportOrderModuleProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [savedUploadData, setSavedUploadData] = useState<Uint8Array | null>(null);

  const fileInputRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);

  const [importedAppLink, setImportedAppLink] = useState<string | null>(null);

  const [uploadApplication, setUploadApplication] = useState(0);
  const [uploadCountriesList, setUploadCountriesList] = useState<ICountryModel[]>([]);
  const [uploadDateStart, setUploadDateStart] = useState<Date | null>(null);
  const [uploadDateStop, setUploadDateStop] = useState<Date | null>(null);

  const [importInError, setImportInError] = useState(false);

  const [openNewAppModal, setOpenNewAppModal] = useState(false);

  const {data: allApplicationsList = [], isLoading} = useGetAppsQuery({offset: 0, limit: 999, search: ''});

  const {setCountriesList} = useActions();

  useEffect(() => {
    if (allApplicationsList.length && savedUploadData && uploadedFile) {
      handleFileUpload(uploadedFile, savedUploadData);
    }
  }, [allApplicationsList]);

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    setImportInError(false);

    if (file) {
      setUploadedFile(file);
      handleFileUpload(file, null);
    } else if (!file && uploadedFile) {
      return;
    } else {
      setUploadedFile(null);
    }
  };

  const handleImportCampaign = () => {
    handleSetOrderType('install', null);
    handleSetOrderApp(uploadApplication);
    handleSetDateStart(uploadDateStart);
    handleSetDateStop(uploadDateStop);
    handleSetCountriesListInStorage(uploadCountriesList);
    setCountriesList(uploadCountriesList);
    handleCloseImportModal();
  };

  const handleRemoveUploadedFile = () => {
    setUploadedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleReturnImportError = (errorText: string) => {
    throwNewToastError(errorText);
    setImportInError(true);
    handleRemoveUploadedFile();
  };

  const handleFileUpload = (importedFile: File, storageData: Uint8Array | null) => {
    let file: File = importedFile;

    if (getFileExtension(file.name) !== 'xlsx') {
      throwNewToastError('Wrong filetype');
      return;
    }

    const reader = new FileReader();
    const currentDate = new Date(new Date().toISOString().slice(0, 10));

    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        let data;
        if (storageData) {
          data = storageData;
        } else if (e.target) {
          const result = e.target.result;
          data = new Uint8Array(result as ArrayBuffer);
        } else return;

        const workbook = XLSX.read(data, {type: 'array'});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const sheetData: any[][] = XLSX.utils.sheet_to_json(worksheet, {header: 1});

        const importedApp = allApplicationsList.find(app => getStoreLinkId(app.url) === getStoreLinkId(sheetData[1][0]));

        //если импортируемое приложение не заведено в системе
        if (!importedApp) {
          setSavedUploadData(data);
          setImportedAppLink(sheetData[1][0]);
          setOpenNewAppModal(true);
          throwNewToastError('This application is not regulated');
          return;
        }

        //список дат из файла
        const dateList = getImportedDateList(worksheet);

        if (!dateList) {
          return;
        }

        if (dateList.find(date => date === 'Invalid date')) {
          handleReturnImportError('Please check the date format or write to support');
          return;
        }

        if (new Date(dateList[dateList.length - 1]) < currentDate) {
          handleReturnImportError('Campaign expired');
          return;
        }

        //список дат, которые еще не прошли
        const actuallyDateList = dateList.filter((date) => {
          return new Date(date) >= new Date(currentDate);
        });

        //кол-во удаленных дат
        const quantityDeletedDate = dateList.length - actuallyDateList.length;

        let result: {
          date_start: string,
          date_stop: string,
          countries: ICountryModel[]
        } = {
          date_start: actuallyDateList[0],
          date_stop: actuallyDateList[actuallyDateList.length - 1],
          countries: []
        };

        let country = '';

        let tempGeoKeywords: ICountryModel = {geo: null, keywords: {}};

        for (let i = 1; sheetData.length; i++) {
          const row = sheetData[i];

          if (!row) {
            break;
          }

          if (!row.length) {
            continue;
          }

          if (row.length && !String(row[0])) {
            handleReturnImportError(`Check the filling of the key on the line ${i + 1}`);
            return;
          }

          //удаляет ключи уже прошедших дат
          if (quantityDeletedDate > 0 && row.length > 1) {
            if (quantityDeletedDate >= row.length - 1) {
              sheetData.splice(i, 1);
              continue;
            } else {
              row.splice(1, quantityDeletedDate);
            }
          }

          //замена 'empty' на ноль
          for (let j = 0; j < row.length; j++) {
            if (row.length > 1 && !row[j]) {
              row[j] = Number(0);
            }
          }

          //добавляет нули до конечной даты для каждого ключа
          if ((row.length - 1 < actuallyDateList.length) && row.length > 1) {
            while (row.length - 1 < actuallyDateList.length) {
              row.push(0);
            }
          }

          //если ключей больше чем проставленных дат, то возвращается ошибка
          if (row.length > 1 && row.length - 1 > actuallyDateList.length) {
            handleReturnImportError('The list of keys is longer than the list of dates. Check if the table is filled in correctly');
            return;
          }

          //переключение на заполнение новой страны
          if (!String(row[0])?.includes('.') && row.length === 1) {
            if (tempGeoKeywords.geo !== null) {
              result.countries.push(tempGeoKeywords);
            }
            const countryCellValue = String(row[0]);
            const currentCountryISO = getCountryByAssociation(countryCellValue);

            if (currentCountryISO) {
              country = currentCountryISO;
              tempGeoKeywords = {geo: country, keywords: {}};
            } else {
              country = countryCellValue;
              tempGeoKeywords = {geo: country, keywords: {}};
            }

          } else {
            let keyword = '';
            for (const cell in row) {
              if (row.length > 1) {
                if (Number(cell) !== 0) {
                  if (!tempGeoKeywords.keywords[actuallyDateList[Number(cell) - 1]]) {
                    tempGeoKeywords.keywords[actuallyDateList[Number(cell) - 1]] = {};
                  }
                  if (row[cell]) {
                    tempGeoKeywords.keywords[actuallyDateList[Number(cell) - 1]][keyword] = row[cell];
                  } else {
                    tempGeoKeywords.keywords[actuallyDateList[Number(cell) - 1]][keyword] = 0;
                  }
                } else {
                  keyword = row[cell];
                }
              }
            }
          }
        }
        result.countries.push(tempGeoKeywords);

        setSavedUploadData(null);

        setUploadDateStart(getDateWithoutGTMOffset(new Date(result.date_start)));
        setUploadDateStop(getDateWithoutGTMOffset(new Date(result.date_stop)));
        setUploadApplication(importedApp.id);
        setUploadCountriesList(result.countries);
      } catch (error: any) {
        console.error(error);
        throwNewToastError(error.message);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleCloseNewAppModal = () => {
    setOpenNewAppModal(false);
  };

  return (
    <ImportModal ref={ref} tabIndex={-1}>
      <ImportModalTitle>Import</ImportModalTitle>
      <ImportModalList>
        <ImportModalItem>Download the ready-made template.</ImportModalItem>
        <ImportModalItem>Enter your keywords.</ImportModalItem>
        <ImportModalItem>Set the required dates according to the format provided in the template.</ImportModalItem>
        <ImportModalItem>Enter the number of installations for each keyword.</ImportModalItem>
        <ImportModalItem>Save and upload the finished order file.</ImportModalItem>
      </ImportModalList>
      <ImportModalListSpan>Prohibited data:</ImportModalListSpan>
      <ImportModalList>
        <ImportModalItem>Specifying the number of installations if the keyword string is empty.</ImportModalItem>
        <ImportModalItem>Specifying the number of installations in the line with the country name.</ImportModalItem>
        <ImportModalItem>Empty spaces in the row with dates, repetition of dates.</ImportModalItem>
      </ImportModalList>
      <ImportModalAttention>
        Please note that all past dates in the file will not be included in the order.<br/>
        Only dates from the current date will be added to the order.
      </ImportModalAttention>
      <DownloadTemplateButton/>
      <ImportModalButtonsBlock>
        {!!uploadedFile &&
          <UploadedFileBlock>
            <BasicSmallSpan>{uploadedFile.name}</BasicSmallSpan>
            <ClearInputButton onClick={handleRemoveUploadedFile}/>
          </UploadedFileBlock>
        }
        {importInError && <UploadedFileError>Sorry, the file is not uploaded</UploadedFileError>}
        <ImportModalInputLabel>
          Import file
          <ImportModalInput type={'file'} onChange={handleFileInputChange} ref={fileInputRef}/>
        </ImportModalInputLabel>
        <BasicButton
          onClick={handleImportCampaign}
          size={'md'}
          text={'Apply'}
          variant={'blue'}
          disabled={!uploadedFile}/>
      </ImportModalButtonsBlock>
      <Modal open={openNewAppModal}>
        <AddNewAppModal onClose={handleCloseNewAppModal} incomeAppLink={importedAppLink}/>
      </Modal>
      {isLoading && <CustomLoader backdrop={true} size="lg" loadingText="Loading..."/>}
    </ImportModal>
  );
});

export default ImportOrderModule;