import moment from 'moment';

export const DATE_FORMAT: string = 'YYYY-MM-DD';

export const getFormattedDate = (date: Date): string => {
  return moment(date).format(DATE_FORMAT);
};

export const getDateWithoutGTMOffset = (date: Date): Date => {
  const offsetMinutes = moment(date).utcOffset();

  const newDate = new Date(date);

  return new Date(newDate.setMinutes(date.getMinutes() - offsetMinutes));
};

export const getOneDayAgo = (dateString: string): string => {
  let dateParts = dateString.split('-');
  let year = parseInt(dateParts[0]);
  let month = parseInt(dateParts[1]) - 1;
  let day = parseInt(dateParts[2]);

  let date = new Date(year, month, day);
  date.setDate(date.getDate() - 1);

  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

export const getOneDayAhead = (dateString: string): string => {
  let dateParts = dateString.split('-');
  let year = parseInt(dateParts[0]);
  let month = parseInt(dateParts[1]) - 1;
  let day = parseInt(dateParts[2]);

  let date = new Date(year, month, day);
  date.setDate(date.getDate() + 1);

  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

export const getOneMonthAgo = (date: string): string => {
  const previousDate = new Date(date);
  previousDate.setMonth(previousDate.getMonth() - 1);
  return moment(previousDate).format('YYYY-MM-DD');
};

export const getOneWeekAgo = (date: string): string => {
  const previousDate = new Date(date);
  previousDate.setDate(previousDate.getDate() - 7);
  return moment(previousDate).format('YYYY-MM-DD');
};

export const getDatesBetween = (startDate: string, stopDate: string): string[] => {
  let dates: string[] = [];

  const currentStartDate = new Date(Date.UTC(
    parseInt(startDate.slice(0, 4)),
    parseInt(startDate.slice(5, 7)) - 1,
    parseInt(startDate.slice(8, 10))
  ));

  const currentStopDate = new Date(Date.UTC(
    parseInt(stopDate.slice(0, 4)),
    parseInt(stopDate.slice(5, 7)) - 1,
    parseInt(stopDate.slice(8, 10))
  ));

  while (currentStartDate <= currentStopDate) {
    dates.push(currentStartDate.toISOString().split('T')[0]);

    currentStartDate.setUTCDate(currentStartDate.getUTCDate() + 1);
  }

  return dates;
};

export const checkThePastDate = (date: string): boolean => {
  // Преобразуем переданную дату в начало дня в UTC
  const checkingDate = new Date(Date.UTC(
    parseInt(date.slice(0, 4)),
    parseInt(date.slice(5, 7)) - 1,
    parseInt(date.slice(8, 10))
  ));

  // Получаем текущую дату в UTC и приводим её к началу дня
  const now = new Date();
  const currentDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));

  return checkingDate >= currentDate;
};

export function formatWeekString(week: string | number): string {
  // Регулярные выражения для проверки форматов YYYY-MM-DD и YYYY-MM
  const dateFormatFull = /^\d{4}-\d{2}-\d{2}$/;
  const dateFormatYearMonth = /^\d{4}-\d{2}$/;

  const weekString = String(week);

  // Если входная строка соответствует одному из форматов даты, вернуть её без изменений
  if (dateFormatFull.test(weekString) || dateFormatYearMonth.test(weekString)) {
    return weekString;
  }

  // Получаем год и номер недели
  const year = parseInt(weekString.slice(0, 4), 10);
  const weekNumber = parseInt(weekString.slice(4), 10);

  // Функция для получения даты начала недели
  function getDateOfISOWeek(week: number, year: number): Date {
    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dayOfWeek = simple.getDay();
    const isoWeekStart = simple;
    if (dayOfWeek <= 4) {
      isoWeekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
      isoWeekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return isoWeekStart;
  }

  // Находим дату начала недели
  const startDate = getDateOfISOWeek(weekNumber, year);
  // Конец недели - это шесть дней после начала
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  // Форматируем дни и месяцы для строки
  const formatDay = (date: Date) => String(date.getDate()).padStart(2, '0');
  const formatMonth = (date: Date) => String(date.getMonth() + 1).padStart(2, '0');

  // Создаем строку в формате `дд-дд/мм` или `дд/мм-дд/мм`
  if (startDate.getMonth() === endDate.getMonth()) {
    return `${formatDay(startDate)}-${formatDay(endDate)}/${formatMonth(startDate)}`;
  } else {
    return `${formatDay(startDate)}/${formatMonth(startDate)}-${formatDay(endDate)}/${formatMonth(endDate)}`;
  }
}

export const checkCurrencyTime = (dateStart: string) => {
  // Преобразование строки в дату
  const date = new Date(dateStart);
  // Получение текущей даты в UTC
  const now = new Date();
  // Преобразование текущей даты в UTC
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

  // Проверка, является ли переданная дата сегодняшней
  const isToday = date.toDateString() === now.toDateString();

  // Если дата сегодняшняя, проверяем, не закончился ли рабочий день до 15:00 по UTC
  if (isToday) {
    // Установка времени до 15:00 по UTC
    now.setUTCHours(15, 0, 0, 0);

    // Сравнение текущего времени с временем окончания рабочего дня
    return now.getTime() > Date.now();
  }

  // Если дата не сегодняшняя, возвращаем false, так как рабочий день не может закончиться
  return true;
};