import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../Theme/defaultTheme';
import {BasicSmallSpan} from '../../../../../../Theme/basicElements';

export const StatisticFilterBlock = styled.div`
  display: flex;
  gap: 32px;
  align-items: flex-end;
`;

export const AppsFilterBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;
  gap: 8px;
`;

export const SelectedAppsFilterList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  gap: 8px;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const SelectedAppFilterItem = styled.li<IThemeProvider>`
  background-color: ${props => props.theme.colors.secondary_btn};
  padding: 4px 8px;
  display: flex;
  gap: 4px;
  border-radius: 8px;
`;

export const SelectedAppTitle = styled(BasicSmallSpan)`
  color: ${props => props.theme.colors.white};
`;

export const SelectedAppDeleteBtn = styled.div`
  width: 16px;
  height: 16px;
  color: ${props => props.theme.colors.white_40};

  & svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;