import styled from 'styled-components';

export const AppsListStatisticWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const StatisticTableBlock = styled.div`
  position: relative;
  max-width: 100%;
  width: min-content;
  height: 100%;
  overflow-y: auto;
  margin-top: 40px;
`;

export const StatisticTableEmptyText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;

  font-family: ${props => props.theme.font_style.font_family};
  font-size: 30px;
  color: ${props => props.theme.colors.grey};
  width: max-content;

  transform: translate(-50%, -50%);
`;

export const StatisticExportBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;