import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {ICampaignInfo} from '../../../../Models/ApiModels';
import {ICountryModel} from '../../Models/CountryModel';
import {setDataToStorage} from '../../../../Utils/localStorageHelpers';
import {LS_EXTEND_ORDER_COUNTRIES, LS_EXTEND_ORDER_ID} from '../../Constants/NewCampaignLSConstants';
import {useGetCampaignInfoQuery} from '../../../../Store/APIs/Campaign.api';
import {useActions} from '../../../../Hooks/redux/useActions';
import {DATE_FORMAT, getDateWithoutGTMOffset, getOneDayAhead} from '../../../../Utils/DateUtils';
import {getNewDateArray} from '../../Helpers/CreateOrderHelpers';
import moment from 'moment';
import {getDateListFromCampaignKeywords, ParseExtendKeywords} from '../../Helpers/CampaignParsers';
import CreateCampaignFormPresenter from '../../Presentor';
import {Loader} from 'rsuite';

interface IExtendOrderModuleProps {
  id: number;
  handleCreateNewOrder: () => void;
}

const ExtendOrderModule = forwardRef(({id, handleCreateNewOrder}: IExtendOrderModuleProps, ref) => {
  const [campaignData, setCampaignData] = useState<ICampaignInfo | null>(null);
  const currentTodayDate = new Date(new Date().setHours(0, 0, 0, 0));

  const {data, isLoading} = useGetCampaignInfoQuery(id);

  const internalRef = useRef();

  const {
    setOrderDateStart,
    setOrderDateStop,
    setOrderType,
    setOrderApp,
    setOrderAttributes,
    setOrderDelayedStart,
    setCountriesList,
    clearAllStateFields,
    setActiveScenario,
    setDelayedStartEnable
  } = useActions();

  const handleSetCountriesListInStorage = (countriesList: ICountryModel[]) => {
    if (countriesList.length) {
      setDataToStorage(LS_EXTEND_ORDER_COUNTRIES, countriesList);
    } else {
      localStorage.removeItem(LS_EXTEND_ORDER_COUNTRIES);
    }
  };

  useEffect(() => {
    setActiveScenario('extend');

    return () => {
      clearAllStateFields();
    };
  }, []);

  useEffect(() => {
    if (data) {
      setCampaignData(data);
      setOrderApp(data.app);
      setOrderType(data.type);
      setOrderAttributes(data.settings_message);

      const delayedStartValue = data.delayed_start;
      if (delayedStartValue) {
        setDelayedStartEnable(true);
        setOrderDelayedStart(data.delayed_start);
      }

      let newStartDate;
      let newDayArray: string[];

      const oldDateList = getDateListFromCampaignKeywords(data.keywords);
      const currentEndDate = getDateWithoutGTMOffset(new Date(oldDateList[oldDateList.length - 1]));

      if (currentTodayDate <= currentEndDate) {
        newStartDate = getOneDayAhead(data.date_stop);
      } else {
        newStartDate = moment(getDateWithoutGTMOffset(new Date())).format(DATE_FORMAT);
      }
      newDayArray = getNewDateArray(newStartDate, oldDateList[0], oldDateList[oldDateList.length - 1]);

      setOrderDateStart(newStartDate);
      if (newDayArray.length) {
        setOrderDateStop(newDayArray[newDayArray.length - 1] ?? null);
      }

      const storageCampaignId = Number(localStorage.getItem(LS_EXTEND_ORDER_ID));

      if (storageCampaignId === id) {
        const storageCountries = localStorage.getItem(LS_EXTEND_ORDER_COUNTRIES);
        setCountriesList(storageCountries ? JSON.parse(storageCountries) : []);
      } else {
        setDataToStorage(LS_EXTEND_ORDER_ID, id);

        const newCountries = ParseExtendKeywords(data, newStartDate, newDayArray[newDayArray.length - 1]);
        setCountriesList(newCountries);
        handleSetCountriesListInStorage(newCountries);
      }
    }

  }, [data]);

  const handleResetAllForms = () => {
    if (campaignData) {
      const currentEndDate = campaignData.date_stop;
      const newStartDate = getOneDayAhead(currentEndDate);
      const newDayArray = getNewDateArray(newStartDate, campaignData.date_start, campaignData.date_stop);

      const clearCountry = ParseExtendKeywords(campaignData, newStartDate, newDayArray[newDayArray.length - 1]);
      setCountriesList(clearCountry);
      handleSetCountriesListInStorage(clearCountry);
    }
  };

  const handleSetOrderType = () => {
  };
  const handleSetOrderApp = () => {
  };
  const handleSetDateStart = () => {
  };
  const handleSetDateStop = () => {
  };
  const handleSetAttributesValue = () => {
  };
  const handleSetDelayStartAttribute = () => {

  };
  const handleSetDelayStartValue = () => {
  };
  const handleCreateNewCampaign = () => {
    handleCreateNewOrder();
  };

  useImperativeHandle(ref, () => ({
    childFunction: handleResetAllForms,
    internalRef: internalRef.current,
  }));

  return (
    <>
      <CreateCampaignFormPresenter
        scenario={'extend'}
        handleSetOrderType={handleSetOrderType}
        handleSetOrderApp={handleSetOrderApp}
        handleSetDateStart={handleSetDateStart}
        handleSetDateStop={handleSetDateStop}
        handleSetAttributesValue={handleSetAttributesValue}
        handleSetDelayStartValue={handleSetDelayStartValue}
        handleSetCountriesListInStorage={handleSetCountriesListInStorage}
        handleCreateNewCampaign={handleCreateNewCampaign}
        handleResetAllForms={handleResetAllForms}
        handleSetDelayStartAttribute={handleSetDelayStartAttribute}
      />
      {isLoading && <Loader backdrop size="lg" content="Loading..." vertical/>}
    </>
  );
});

export default ExtendOrderModule;