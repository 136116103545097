import styled from 'styled-components';
import {BasicTableTd, BasicTableTh} from '../../../../../../Theme/basicElements';
import {IThemeProvider} from '../../../../../../Theme/defaultTheme';

export const StatisticTh = styled(BasicTableTh)<IThemeProvider>`
  padding: 10px;
  background-color: ${props => props.theme.colors.primary_light};
  color: ${props => props.theme.colors.black_60};
  border-bottom: 1px solid ${props => props.theme.colors.grey_semi};

  width: min-content;

  &[data-align='left'] {
    text-align: left;
  }

  &[data-align='right'] {
    text-align: right;
  }

`;

export const StatisticTr = styled.tr`
  &[data-row='total'] {
    & td {
      background-color: #F7F9FB;
    }
  }
`;

export const StatisticTd = styled(BasicTableTd)`
  padding: 16px 10px;
  text-align: center;
  color: ${props => props.theme.colors.black};

  &[data-align='left'] {
    text-align: left;
  }

  &[data-align='right'] {
    text-align: right;
  }
`;

export const StatisticAppRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const TableIcon = styled.div`
  width: 24px;
  height: 24px;

  & img {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }

  & a {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }
`;


export const StatisticSumTd = styled(StatisticTd)<IThemeProvider>`
  background-color: #C6D6F8;
  font-weight: ${props => props.theme.font_style.font_weight.bold};
`;

export const StatisticTHead = styled.thead`
  position: sticky;
  top: -1px;
`;

export const StatisticTFoot = styled.tfoot`
  position: sticky;
  bottom: -1px;
`;

