import React, {ForwardedRef, forwardRef, useEffect} from 'react';
import {StatisticModal} from './styled';
import StatisticAppsList from './components/lists/StatisticAppsList/StatisticAppsList';
import DetailAppStatistic from './components/lists/DetailAppStatistic/DetailAppStatistic';
import {useActions} from '../../../../Hooks/redux/useActions';
import {useAppSelector} from '../../../../Hooks/redux/useAppSelector';

interface OrdersStatisticModuleProps {
  initDateStart: string,
  initDateStop: string
}

const OrdersStatisticModule: React.ForwardRefExoticComponent<OrdersStatisticModuleProps> = forwardRef(({
                                                                                                         initDateStart,
                                                                                                         initDateStop
                                                                                                       }, ref: ForwardedRef<HTMLDivElement>) => {
  const {setStatisticDateStart, setStatisticDateStop, clearStatisticState} = useActions();
  const {appInfo} = useAppSelector(state => state.myOrdersStatisticSlice);

  useEffect(() => {
    setStatisticDateStart(initDateStart);
    setStatisticDateStop(initDateStop);

    return () => {
      clearStatisticState();
    };
  }, []);

  return (
    <StatisticModal ref={ref} tabIndex={-1}>
      {!appInfo ?
        <StatisticAppsList/>
        :
        <DetailAppStatistic detailId={appInfo.application}/>
      }
    </StatisticModal>
  );
});

export default OrdersStatisticModule;