import React, {FC} from 'react';
import {
  AppsListStatisticTitle
} from './styled';
import StatisticFilter from '../../StatisticFilter/StatisticFilter';
import StatisticTable from '../../tables/StatisticTable/StatisticTable';
import BasicButton from '../../../../../../../UI/BasicButton';
import {AppsListStatisticWrapper, StatisticExportBlock, StatisticTableBlock} from '../styled';
import {IOrdersStatisticItem, useGetStatisticQuery} from '../../../../../../../Store/APIs/Campaign.api';
import BlockLoader from '../../../../../../../Components/Loaders/BlockLoader';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {utils, writeFile} from 'xlsx';
import {getTotalValue} from '../../../utils';

interface StatisticAppsListProps {

}

const EXPORT_TABLE_COLUMNS: { key: keyof IOrdersStatisticItem, title: string }[] = [
  {
    key: 'application_name',
    title: 'Application'
  },
  {
    title: 'Store',
    key: 'application_store_id',
  },
  {
    title: 'Countries',
    key: 'countries',
  },
  {
    title: 'Keywords',
    key: 'keywords',
  },
  {
    title: 'Active Campaigns',
    key: 'activeCampaigns',
  },
  {
    title: 'Completed Campaigns',
    key: 'completedCampaigns',
  },
  {
    title: 'Cancelled Campaigns',
    key: 'cancelledCampaigns',
  },
  {
    title: 'Installs',
    key: 'installsCount',
  },
  {
    title: 'Rates',
    key: 'ratesCount',
  },
  {
    title: 'Cost',
    key: 'cost',
  },
];

const StatisticAppsList: FC<StatisticAppsListProps> = ({}) => {
  const {dateStart, dateStop, selectedApps} = useAppSelector(state => state.myOrdersStatisticSlice);

  const {data = [], isLoading, isSuccess} = useGetStatisticQuery({
    dateStart,
    dateStop,
    apps: (selectedApps.map(app => app.id))
  });

  const handleExport = () => {
    if (data.length) {
      const headerRow = [...EXPORT_TABLE_COLUMNS.map(column => column.title)];
      const worksheetData = [headerRow];

      data.forEach(row => {
        const sheetRow: string[] = [];

        EXPORT_TABLE_COLUMNS.forEach(col => {
          let newCell;

          if (col.key === 'application_store_id') {
            newCell = row[col.key].includes('.') ? 'Play market' : 'App store';
          } else if (col.key === 'cost') {
            newCell = row[col.key].toFixed(2);
          } else {
            newCell = row[col.key];
          }

          sheetRow.push(String(newCell));
        });


        worksheetData.push(sheetRow);
      });

      const footerRow = EXPORT_TABLE_COLUMNS
        .map(col => {
          switch (col.key) {
            case 'application_store_id':
              return '';
            case 'application_name':
              return 'Total';
            case 'cost':
              return String(getTotalValue(data, col.key).toFixed(2));

            default:
              return String(getTotalValue(data, col.key));
          }
        });

      worksheetData.push(footerRow);

      const ws = utils.aoa_to_sheet(worksheetData);
      const wb = utils.book_new();

      utils.book_append_sheet(wb, ws, `Data`);

      writeFile(wb, `appreport_${dateStart}-${dateStop}.xlsx`);
    }
  };

  return (
    <AppsListStatisticWrapper>
      <AppsListStatisticTitle>Statistic</AppsListStatisticTitle>
      <StatisticFilter/>
      <StatisticTableBlock>
        {isLoading ? <BlockLoader size={'lg'}/> : null}
        {(!isLoading && !!data.length && isSuccess) ? <StatisticTable statisticList={data}/> : null}
      </StatisticTableBlock>
      <StatisticExportBlock>
        <BasicButton
          onClick={handleExport}
          size={'md'}
          text={'Export XLXS'}
          variant={'blue'}
          disabled={!isSuccess || !data.length}/>
      </StatisticExportBlock>
    </AppsListStatisticWrapper>
  );
};

export default StatisticAppsList;