import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ICountryModel, IKeyword} from '../Models/CountryModel';

export type CreateOrderScenarioType = 'new_order' | 'promote' | 'duplicate' | 'extend' | null;

interface INewOrderState {
  type: string | null;
  app: number;
  dateStart: string | null;
  dateStop: string | null;
  attributesValue: number;
  delayedStartValue: string | null;
  countriesList: ICountryModel[];
  activeScenario: CreateOrderScenarioType;

  delayedStartEnable: boolean;
}

const initialState: INewOrderState = {
  type: null,
  app: 0,
  dateStart: null,
  dateStop: null,
  attributesValue: 0,
  delayedStartValue: null,
  countriesList: [],
  activeScenario: null,

  delayedStartEnable: false
};

export const newOrderSlice = createSlice({
  name: 'newCampaign',
  initialState,
  reducers: {
    setOrderType(state, action: PayloadAction<string | null>) {
      state.type = action.payload;
    },
    setOrderApp(state, action: PayloadAction<number>) {
      state.app = action.payload;
    },
    setOrderDateStart(state, action: PayloadAction<string | null>) {
      state.dateStart = action.payload;
    },
    setOrderDateStop(state, action: PayloadAction<string | null>) {
      state.dateStop = action.payload;
    },
    setOrderAttributes(state, action: PayloadAction<number>) {
      state.attributesValue = action.payload;
    },
    setOrderDelayedStart(state, action: PayloadAction<string | null>) {
      state.delayedStartValue = action.payload;
    },
    setCountriesList(state, action: PayloadAction<ICountryModel[]>) {
      state.countriesList = action.payload;
    },
    changeGeoInSelectedCountry(state, action: PayloadAction<{ geo: string | null, countryIndex: number }>) {
      const {geo, countryIndex} = action.payload;
      state.countriesList[countryIndex].geo = geo;
    },
    addNewCountry(state) {
      state.countriesList.push({geo: null, keywords: {}});
    },
    addNewBpCountry(state, action: PayloadAction<IKeyword>) {
      state.countriesList.push({geo: null, keywords: action.payload});
    },
    removeSelectedCountry(state, action: PayloadAction<number>) {
      state.countriesList.splice(action.payload, 1);
    },
    cloneSelectedCountry(state, action: PayloadAction<number>) {
      state.countriesList.splice(action.payload + 1, 0, {...state.countriesList[action.payload], geo: null});
    },
    putEditedKeywordsInCountry(state, action: PayloadAction<{ newKeywords: IKeyword, countryIndex: number }>) {
      const {newKeywords, countryIndex} = action.payload;
      if (state.countriesList.length) {
        state.countriesList[countryIndex].keywords = newKeywords;
      }
    },
    clearAllStateFields(state) {
      state.type = null;
      state.app = 0;
      state.dateStart = null;
      state.dateStop = null;
      state.attributesValue = 0;
      state.delayedStartValue = null;
      state.delayedStartEnable = false;
      state.countriesList = [];
    },
    setActiveScenario(state, action: PayloadAction<'new_order' | 'promote' | 'duplicate' | 'extend' | null>) {
      state.activeScenario = action.payload;
    },
    setDelayedStartEnable(state, action: PayloadAction<boolean>) {
      state.delayedStartEnable = action.payload;
    }
  }
});

export const newOrderActions = newOrderSlice.actions;

export const newOrderReducer = newOrderSlice.reducer;