import React, {useRef, useState} from 'react';
import {
  KeywordsAdditionLabel,
  KeywordsAdditionRow,
  KeywordsAdditionTextarea,
  KeywordsAdditionTitle,
  KeywordsControlBlock
} from './styled';
import HelpButton from '../../../../../Components/HelpButton';
import BasicButton from '../../../../../../../UI/BasicButton';
import {getDatesListFromCountry, getKeysFromCountry} from '../../../../../Helpers/CreateOrderHelpers';
import {ICountryModel, IKeyword} from '../../../../../Models/CountryModel';
import {throwNewToastError} from '../../../../../../../Utils/MainUtils';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {getDatesBetween} from '../../../../../../../Utils/DateUtils';
import {BasicRedAccentSpan} from '../../../../../../../Theme/basicElements';

interface ICountryKeywordsAdditionBlockProps {
  handleRemoveCurrentCountry: () => void;
  country: ICountryModel;
  handleSetCountryKeywords: (keywords: IKeyword) => void;
}

const CountryKeywordsAdditionBlock: React.FC<ICountryKeywordsAdditionBlockProps> = ({
                                                                                      handleRemoveCurrentCountry,
                                                                                      country,
                                                                                      handleSetCountryKeywords
                                                                                    }) => {
  const [textAreaValues, setTextAreaValues] = useState<string[]>([]);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const {dateStart, dateStop} = useAppSelector(state => state.newOrderSlice);

  const getKeywordsFromTextarea = (textareaValue: string): string[] => {
    const lines = textareaValue.split('\n');
    const keywords: string[] = [];

    lines.forEach(line => {
      const trimmedLine = line.trim().toLowerCase();

      if (trimmedLine !== '') {
        const splitKeywords = trimmedLine.split(',').map(keyword => keyword.trim()).filter(keyword => keyword !== '');
        keywords.push(...splitKeywords);
      }
    });

    return keywords;
  };

  const handleAddNewKeysFromTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValues(getKeywordsFromTextarea(event.target.value));
  };

  const dateListsCheck = (newDateArray: string[]): boolean => {
    const oldDateList = getDatesListFromCountry(country);
    const newDateList = newDateArray;

    if (oldDateList.length === 0) {
      return true;
    }

    if (oldDateList.length !== newDateList.length) {
      return false;
    }

    return oldDateList.every((date, index) => date === newDateList[index]);
  };

  const handleAddNewKeywords = () => {
    //функция добавления новых ключей в страну
    if (!dateStart || !dateStop) {
      throwNewToastError('Please select start and end date');
      return;
    }
    if (!textAreaValues.length) {
      throwNewToastError('Enter the keywords');
      return;
    }

    const dateArray = getDatesBetween(dateStart, dateStop);

    let newCountryKeywords: IKeyword = JSON.parse(JSON.stringify(country.keywords));

    //проверка на соответствие массивов дат (если даты изменились, старые ключи остаются, новые добавляются, дата применяется новая)
    if (dateListsCheck(dateArray)) {
      dateArray.forEach(date => {
        if (!newCountryKeywords[date]) {
          newCountryKeywords[date] = {};
        }
        textAreaValues.forEach(key => newCountryKeywords[date][key] = 0);
      });
    } else {
      const oldKeys = getKeysFromCountry(country);
      const allKeysArray = [...oldKeys, ...textAreaValues];

      newCountryKeywords = {};

      dateArray.forEach(date => {
        if (!newCountryKeywords[date]) {
          newCountryKeywords[date] = {};
        }
        allKeysArray.forEach(key => newCountryKeywords[date][key] = 0);
      });
    }

    setTextAreaValues([]);
    if (textAreaRef.current) {
      textAreaRef.current.value = '';
    }

    handleSetCountryKeywords(newCountryKeywords);
  };

  return (
    <KeywordsControlBlock>
      <KeywordsAdditionRow>
        <KeywordsAdditionLabel>
          <KeywordsAdditionTitle>Keywords <BasicRedAccentSpan>*</BasicRedAccentSpan></KeywordsAdditionTitle>
          <HelpButton
            popupText={'Enter keywords. Each individual keyword must be on a new line or separated by commas.'}/>
        </KeywordsAdditionLabel>
        <KeywordsAdditionTextarea
          rows={3}
          ref={textAreaRef}
          onChange={handleAddNewKeysFromTextarea}
        />
      </KeywordsAdditionRow>
      <BasicButton onClick={handleAddNewKeywords} size={'md'} text={'Save keywords'} variant={'blue'}/>
      <BasicButton onClick={handleRemoveCurrentCountry} size={'md'} text={'Remove country'} variant={'red'}/>
    </KeywordsControlBlock>
  );
};

export default CountryKeywordsAdditionBlock;