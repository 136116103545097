export const LS_SHADOW_INSTALLS_COUNTRIES = 'ls_shadow_installs_countries';
export const LS_SHADOW_RATES_COUNTRIES = 'ls_shadow_rates_countries';
export const LS_SHADOW_INSTALLS_BP_COUNTRIES = 'ls_shadow_installs_bp_countries';

export const LS_CREATE_ORDER_TYPE = 'ls_create_type';
export const LS_CREATE_ORDER_APP = 'ls_create_app';
export const LS_CREATE_ORDER_DATE_START = 'ls_create_date_start';
export const LS_CREATE_ORDER_DATE_STOP = 'ls_create_date_stop';
export const LS_CREATE_ORDER_ATTRIBUTES = 'ls_create_attributes';
export const LS_CREATE_ORDER_DELAY_ATTRIBUTE = 'ls_create_delay_attribute';
export const LS_CREATE_ORDER_DELAY_TIME = 'ls_create_delay_time';
export const LS_CREATE_ORDER_COUNTRIES = 'ls_create_countries';

export const LS_PROMOTE_SHADOW_INSTALLS_COUNTRIES = 'ls_promote_shadow_installs_countries';
export const LS_PROMOTE_SHADOW_RATES_COUNTRIES = 'ls_promote_shadow_rates_countries';
export const LS_PROMOTE_SHADOW_INSTALLS_BP_COUNTRIES = 'ls_promote_shadow_installs_bp_countries';

export const LS_PROMOTE_ORDER_TYPE = 'ls_promote_type';
export const LS_PROMOTE_ORDER_APP = 'ls_promote_app';
export const LS_PROMOTE_ORDER_DATE_START = 'ls_promote_date_start';
export const LS_PROMOTE_ORDER_DATE_STOP = 'ls_promote_date_stop';
export const LS_PROMOTE_ORDER_ATTRIBUTES = 'ls_promote_attributes';
export const LS_PROMOTE_ORDER_DELAY_ATTRIBUTE = 'ls_promote_delay_attribute';
export const LS_PROMOTE_ORDER_DELAY_TIME = 'ls_promote_delay_time';
export const LS_PROMOTE_ORDER_COUNTRIES = 'ls_promote_countries';

export const LS_DUPLICATE_ORDER_ID = 'ls_duplicate_id';
export const LS_DUPLICATE_ORDER_DATE_START = 'ls_duplicate_date_start';
export const LS_DUPLICATE_ORDER_DELAY_ATTRIBUTE = 'ls_duplicate_delay_attribute';
export const LS_DUPLICATE_ORDER_DELAY_TIME = 'ls_duplicate_delay_time';
export const LS_DUPLICATE_ORDER_ATTRIBUTES = 'ls_duplicate_checkboxes_value';
export const LS_DUPLICATE_ORDER_DATE_STOP = 'ls_duplicate_date_stop';
export const LS_DUPLICATE_ORDER_COUNTRIES = 'ls_duplicate_countries';

export const LS_EXTEND_ORDER_ID = 'ls_extend_id';
export const LS_EXTEND_ORDER_COUNTRIES = 'ls_extend_countries';