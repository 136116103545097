import React from 'react';
import {ClosedFormBlock, ClosedFormButtonsBlock, ClosedFormTextBlock, ClosedFormTitle} from './styled';
import {BasicContentParagraph} from '../../../../../Theme/basicElements';
import BasicButton from '../../../../../UI/BasicButton';
import {useNavigate} from 'react-router-dom';

const ClosedRegForm = () => {
  const navigate = useNavigate();

  const handleNavigateContact = () => {
    window.location.href = 'https://t.me/asofast';
  };

  const handleGoBack = () => {
    navigate('/authorization');
  };

  return (
    <ClosedFormBlock>
      <ClosedFormTextBlock>
        <ClosedFormTitle>Private registration</ClosedFormTitle>
        <BasicContentParagraph>Write to us to register an account</BasicContentParagraph>
      </ClosedFormTextBlock>
      <ClosedFormButtonsBlock>
        <BasicButton onClick={handleNavigateContact} size={'lg'} text={'Contact us'} variant={'blue'}/>
        <BasicButton onClick={handleGoBack} size={'lg'} text={'Back'} variant={'white'}/>
      </ClosedFormButtonsBlock>
    </ClosedFormBlock>
  );
};

export default ClosedRegForm;