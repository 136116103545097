import React, {forwardRef, useEffect, useImperativeHandle, useRef} from 'react';
import {useActions} from '../../../../Hooks/redux/useActions';
import {getDataFromStorage, setDataToStorage} from '../../../../Utils/localStorageHelpers';
import {
  LS_DUPLICATE_ORDER_DELAY_ATTRIBUTE,
  LS_PROMOTE_ORDER_APP,
  LS_PROMOTE_ORDER_ATTRIBUTES,
  LS_PROMOTE_ORDER_COUNTRIES,
  LS_PROMOTE_ORDER_DATE_START,
  LS_PROMOTE_ORDER_DATE_STOP, LS_PROMOTE_ORDER_DELAY_ATTRIBUTE,
  LS_PROMOTE_ORDER_DELAY_TIME,
  LS_PROMOTE_ORDER_TYPE,
  LS_PROMOTE_SHADOW_INSTALLS_BP_COUNTRIES,
  LS_PROMOTE_SHADOW_INSTALLS_COUNTRIES,
  LS_PROMOTE_SHADOW_RATES_COUNTRIES
} from '../../Constants/NewCampaignLSConstants';
import {checkThePastDate, getFormattedDate} from '../../../../Utils/DateUtils';
import {ICountryModel} from '../../Models/CountryModel';
import CreateCampaignFormPresenter from '../../Presentor';
import {useAppSelector} from '../../../../Hooks/redux/useAppSelector';
import {checkDelayDateIsExist} from '../../Helpers/CreateOrderHelpers';

interface IPromoteApplicationModuleProps {
  promoteAppId: number;
  handleCreateNewOrder: () => void;
}

const PromoteApplicationModule = forwardRef(({
                                               promoteAppId,
                                               handleCreateNewOrder
                                             }: IPromoteApplicationModuleProps, ref) => {
  const {countriesList, delayedStartValue} = useAppSelector(state => state.newOrderSlice);

  const internalRef = useRef();

  const {
    setOrderDateStart,
    setOrderDateStop,
    setOrderType,
    setOrderApp,
    setOrderAttributes,
    setCountriesList,
    clearAllStateFields,
    setActiveScenario,
    setOrderDelayedStart,
    setDelayedStartEnable
  } = useActions();

  const fetchDataFromStorage = () => {
    const type = getDataFromStorage(LS_PROMOTE_ORDER_TYPE);
    !!type && setOrderType(type);

    const dateStart = getDataFromStorage(LS_PROMOTE_ORDER_DATE_START);
    !!dateStart && checkThePastDate(dateStart) && setOrderDateStart(dateStart);

    const dateStop = getDataFromStorage(LS_PROMOTE_ORDER_DATE_STOP);
    !!dateStop && checkThePastDate(dateStop) && setOrderDateStop(dateStop);

    const attributes = getDataFromStorage(LS_PROMOTE_ORDER_ATTRIBUTES);
    !!attributes && setOrderAttributes(Number(attributes));

    const delayAttribute = getDataFromStorage(LS_PROMOTE_ORDER_DELAY_ATTRIBUTE);
    delayAttribute && setDelayedStartEnable(true);

    const delayTime = getDataFromStorage(LS_PROMOTE_ORDER_DELAY_TIME);

    if (delayAttribute && delayTime && dateStart) {
      if (!checkDelayDateIsExist(delayTime, dateStart)) {
        setOrderDelayedStart(delayTime);
      } else {
        localStorage.removeItem(LS_PROMOTE_ORDER_DELAY_TIME);
      }
    }

    const countries = localStorage.getItem(LS_PROMOTE_ORDER_COUNTRIES);
    !!countries && !!dateStart && checkThePastDate(dateStart) && !!dateStop && checkThePastDate(dateStop) && setCountriesList(JSON.parse(countries));
  };

  useEffect(() => {
    setActiveScenario('promote');

    const storageApp = getDataFromStorage(LS_PROMOTE_ORDER_APP);

    if (!!storageApp && Number(storageApp) === promoteAppId) {
      fetchDataFromStorage();
    } else {
      handleResetAllForms();
    }

    handleSetOrderApp(promoteAppId);

    return () => {
      clearAllStateFields();
    };
  }, []);

  const handleSetOrderType = (newType: string | null, prevType: string | null) => {
    setOrderType(newType);

    const typeKeys: {
      [key: string]: string
    } = {
      rate: LS_PROMOTE_SHADOW_INSTALLS_COUNTRIES,
      install: LS_PROMOTE_SHADOW_RATES_COUNTRIES,
      'install_bp': LS_PROMOTE_SHADOW_INSTALLS_BP_COUNTRIES,
    };

    if (prevType && countriesList.length > 0) {
      const key = typeKeys[prevType];
      if (key) {
        setDataToStorage(key, countriesList);
        setCountriesList([]);
        handleSetCountriesListInStorage([]);
      }
    }

    if (newType) {
      setDataToStorage(LS_PROMOTE_ORDER_TYPE, newType);

      const key = typeKeys[newType];
      if (key) {
        const shadowCountries = localStorage.getItem(key);
        if (shadowCountries) {
          const parsedCountries = JSON.parse(shadowCountries);
          setCountriesList(parsedCountries);
          handleSetCountriesListInStorage(parsedCountries);
        }
      }
    } else {
      Object.values(typeKeys).forEach(key => localStorage.removeItem(key));
      localStorage.removeItem(LS_PROMOTE_ORDER_TYPE);
    }
  };

  const handleSetOrderApp = (app: number) => {
    setOrderApp(app);
    if (app) {
      setDataToStorage(LS_PROMOTE_ORDER_APP, app);
    } else {
      localStorage.removeItem(LS_PROMOTE_ORDER_APP);
    }
  };

  const handleSetDateStart = (date: Date | null) => {
    setOrderDateStart(date ? getFormattedDate(date) : null);
    if (date) {
      if (delayedStartValue) {
        handleSetDelayStartValue(null);
      }
      setDataToStorage(LS_PROMOTE_ORDER_DATE_START, getFormattedDate(date));
    } else {
      localStorage.removeItem(LS_PROMOTE_ORDER_DATE_START);
    }
  };

  const handleSetDateStop = (date: Date | null) => {
    setOrderDateStop(date ? getFormattedDate(date) : null);
    if (date) {
      setDataToStorage(LS_PROMOTE_ORDER_DATE_STOP, getFormattedDate(date));
    } else {
      localStorage.removeItem(LS_PROMOTE_ORDER_DATE_STOP);
    }
  };

  const handleSetAttributesValue = (value: number) => {
    setOrderAttributes(value);
    if (value) {
      setDataToStorage(LS_PROMOTE_ORDER_ATTRIBUTES, value);
    } else {
      localStorage.removeItem(LS_PROMOTE_ORDER_ATTRIBUTES);
    }
  };

  const handleSetDelayStartAttribute = (value: boolean) => {
    setDelayedStartEnable(value);

    if (value) {
      setDataToStorage(LS_DUPLICATE_ORDER_DELAY_ATTRIBUTE, value);
    } else {
      localStorage.removeItem(LS_DUPLICATE_ORDER_DELAY_ATTRIBUTE);
      handleSetDelayStartValue(null);
    }
  };

  const handleSetDelayStartValue = (dateString: string | null) => {
    setOrderDelayedStart(dateString);
    if (dateString) {
      setDataToStorage(LS_PROMOTE_ORDER_DELAY_TIME, dateString);
    } else {
      localStorage.removeItem(LS_PROMOTE_ORDER_DELAY_TIME);
    }
  };

  const handleSetCountriesListInStorage = (countriesList: ICountryModel[]) => {
    if (countriesList.length) {
      setDataToStorage(LS_PROMOTE_ORDER_COUNTRIES, countriesList);
    } else {
      localStorage.removeItem(LS_PROMOTE_ORDER_COUNTRIES);
    }
  };

  const handleCreateNewCampaign = () => {
    handleCreateNewOrder();
  };

  const handleResetAllForms = () => {
    handleSetOrderType(null, null);
    handleSetAttributesValue(0);
    handleSetDelayStartValue(null);
    handleSetDelayStartAttribute(false);
    handleSetDateStart(null);
    handleSetDateStop(null);
    handleSetCountriesListInStorage([]);
    setCountriesList([]);
  };

  useImperativeHandle(ref, () => ({
    childFunction: handleResetAllForms,
    internalRef: internalRef.current,
  }));

  return (
    <>
      <CreateCampaignFormPresenter
        scenario={'promote'}
        handleResetAllForms={handleResetAllForms}
        handleCreateNewCampaign={handleCreateNewCampaign}
        handleSetDateStop={handleSetDateStop}
        handleSetDateStart={handleSetDateStart}
        handleSetAttributesValue={handleSetAttributesValue}
        handleSetCountriesListInStorage={handleSetCountriesListInStorage}
        handleSetOrderApp={handleSetOrderApp}
        handleSetOrderType={handleSetOrderType}
        handleSetDelayStartValue={handleSetDelayStartValue}
        handleSetDelayStartAttribute={handleSetDelayStartAttribute}
      />
    </>
  );
});

export default PromoteApplicationModule;