import React from 'react';
import {
  AuthButtonWrapper,
  AuthInputs,
  AuthInputsBlock, AuthSubmitBlock,
  ForgotSpan, StyledForm
} from '../../AuthWindow/styled';
import {
  ERROR_INCORRECT_EMAIL,
  ERROR_INCORRECT_EMAIL_OR_PASSWORD,
  ERROR_INCORRECT_PASSWORD_FIELD,
} from '../../../Constants/AuthErrorsList';
import {Link} from 'react-router-dom';
import BasicAuthInput from '../../../../../UI/BasicAuthInput';
import BasicButton from '../../../../../UI/BasicButton';
import TelegramAuthButton from '../../TelegramAuthButton';
import GoogleAuthButton from '../../GoogleAuthButton';

export interface IAuthFormProps {
  validationErrors: string[];
  username: string;
  password: string;
  handleSetEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSetPassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isVisible: boolean;
  handleSetVisible: (event: React.MouseEvent<HTMLButtonElement>) => void;
  retryPassword?: string;
  handleSetRetryPassword?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmitForm: (e: React.FormEvent<HTMLFormElement>) => void;
  refKey?: string;
}

const AuthForm: React.FC<IAuthFormProps> = ({
                                              validationErrors,
                                              username,
                                              password,
                                              handleSetEmail,
                                              handleSetPassword,
                                              isVisible,
                                              handleSetVisible,
                                              handleSubmitForm,
                                              refKey,

                                            }) => {

  return (
    <StyledForm>
      <AuthInputsBlock>
        <AuthInputs>
          <BasicAuthInput
            type={'text'}
            placeholder={'E-mail'}
            onChange={handleSetEmail}
            value={username}
            fieldType={'email'}
            fieldError={validationErrors.includes(ERROR_INCORRECT_EMAIL) || validationErrors.includes(ERROR_INCORRECT_EMAIL_OR_PASSWORD)}
            errorText={validationErrors.includes(ERROR_INCORRECT_EMAIL) ? ERROR_INCORRECT_EMAIL : ''}
          />
          <BasicAuthInput
            fieldType={'password'}
            fieldError={validationErrors.includes(ERROR_INCORRECT_PASSWORD_FIELD) || validationErrors.includes(ERROR_INCORRECT_EMAIL_OR_PASSWORD)}
            value={password}
            onChange={handleSetPassword}
            placeholder={'Password'}
            type={isVisible ? 'text' : 'password'}
            errorText={validationErrors.includes(ERROR_INCORRECT_PASSWORD_FIELD) ? ERROR_INCORRECT_PASSWORD_FIELD : ''}
            isVisible={isVisible}
            handleSetVisible={handleSetVisible}
          />
        </AuthInputs>
        <ForgotSpan><Link to={'/reset-password'}>Forgot password?</Link></ForgotSpan>
      </AuthInputsBlock>
      <AuthSubmitBlock>
        <BasicButton
          type={'submit'}
          onClick={handleSubmitForm}
          size={'lg'}
          text={'Sign In'}
          variant={'blue'}
        />
      </AuthSubmitBlock>
      <AuthButtonWrapper>
        <TelegramAuthButton refKey={refKey}/>
        <GoogleAuthButton refKey={refKey}/>
      </AuthButtonWrapper>
    </StyledForm>
  );
};

export default AuthForm;