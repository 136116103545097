import React from 'react';
import {AttributeRow, AttributeRowHeading, AttributeRowLabel, AttributeRowTitle} from '../../styled';
import HelpButton from '../../../../../Components/HelpButton';
import BasicCheckboxButton from '../../../../../../../UI/BasicCheckboxButton';
import {CreateOrderScenarioType} from '../../../../../Slicies/newCampaign.slice';

interface DistributeRowProps {
  selectedCheckboxesValues: string[];
  handleSetSelectedCheckboxesValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  activeScenario: CreateOrderScenarioType;
}

const DistributeRow: React.FC<DistributeRowProps> = ({
                                                       selectedCheckboxesValues,
                                                       handleSetSelectedCheckboxesValue,
                                                       activeScenario
                                                     }) => {
  return (
    <AttributeRow>
      <AttributeRowHeading>
        <AttributeRowLabel>
          <AttributeRowTitle>Distribute</AttributeRowTitle>
          <HelpButton
            popupText={'When this option is selected, installations will occur evenly throughout the day. If this option is not selected, installations are made as quickly as possible.'}/>
        </AttributeRowLabel>
        <BasicCheckboxButton
          name={'attributes'}
          value={'distribute'}
          checked={!!selectedCheckboxesValues.find(attribute => attribute === 'distribute')}
          onChange={handleSetSelectedCheckboxesValue}
          isDisabled={activeScenario === 'extend'}
        />
      </AttributeRowHeading>
      <AttributeRowTitle data-is-disabled={!selectedCheckboxesValues.find(attribute => attribute === 'distribute')}>
        Throughout the day
      </AttributeRowTitle>
    </AttributeRow>
  );
};

export default DistributeRow;