import styled from 'styled-components';
import {BasicPageTitle} from '../../../../../Theme/basicElements';
import {IThemeProvider} from '../../../../../Theme/defaultTheme';

export const ClosedFormBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ClosedFormTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  text-align: center;
  align-items: center;

  padding: 0 0 60px;
`;

export const ClosedFormTitle = styled(BasicPageTitle)<IThemeProvider>`
  color: ${props => props.theme.colors.black};

`;


export const ClosedFormButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;
`;

