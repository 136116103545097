import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../Theme/defaultTheme';

interface IProgressItemProps {
  $percent: number,
  'data-status': number,
}

export const ProgressBlock = styled.div<IProgressItemProps & IThemeProvider>`
  position: relative;

  width: 97px;
  background-color: ${props => props.theme.colors.white};
  padding: 6px 0;
  display: flex;
  justify-content: center;
  border: 1px solid ${props => props.theme.colors.secondary_red};
  border-radius: 8px;
  min-height: 28px;

  overflow: hidden;

  &[data-status='1'] {
    border: 1px solid transparent;

    background-color: ${props => props.theme.colors.primary_light};
  }

  &[data-status='2'] {
    border: 1px solid ${props => props.theme.colors.secondary_green_a};

    &:before {
      background-color: ${props => props.theme.colors.secondary_green_a};
    }
  }

  &[data-status='3'] {
    border: 1px solid ${props => props.theme.colors.secondary_blue_a};

    &:before {
      background-color: ${props => props.theme.colors.secondary_blue_a};

      width: 100%;
    }
  }

  &[data-status='4'] {
    border: 1px solid ${props => props.theme.colors.secondary_yellow_a};

    &:before {
      background-color: ${props => props.theme.colors.secondary_yellow_a};
    }
  }

  &[data-status='5'] {
    border: 1px solid ${props => props.theme.colors.secondary_red};

    &:before {
      background-color: ${props => props.theme.colors.secondary_red};
      width: 100%;
    }
  }

  &[data-status='6'] {
    border: 1px solid ${props => props.theme.colors.secondary_blue_b};

    &:before {
      background-color: ${props => props.theme.colors.secondary_blue_b};
    }
  }

  &[data-status='7'] {
    border: 1px solid #E84FE2;

    &:before {
      background-color: #E84FE2;
    }
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 0;

    top: 0;
    left: 0;

    width: ${props => props.$percent}%;
    height: 100%;

    background-color: ${props => props.theme.colors.secondary_red};
  }
`;

export const ProgressPrepared = styled.div<IThemeProvider>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 50%;
  height: 3px;
  border-radius: 2px;
  background-color: ${props => props.theme.colors.grey};
`;

export const ProgressValue = styled.span<IThemeProvider>`
  position: relative;
  z-index: 1;
  display: block;
  font-size: ${props => props.theme.font_sizes.small};
  font-family: ${props => props.theme.font_style.font_family};
  color: ${props => props.theme.colors.black};
`;